/*
* Bootstrap extensions
*/
/* GLOBAL STYLES */
/* Padding below the footer and lighter body text */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);
body {
  color: #5a5a5a; }

/* CUSTOMIZE THE NAVBAR  */
/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20; }

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper > .container {
  padding-right: 0;
  padding-left: 0; }

.navbar-wrapper .navbar {
  padding-right: 15px;
  padding-left: 15px; }

.navbar-wrapper .navbar .container {
  width: auto; }

/* CUSTOMIZE THE CAROUSEL  */
/* Carousel base class */
.carousel {
  height: 500px; }

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  top: 5%;
  text-align: left;
  left: 15%; }

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #777; }

.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px; }

/* MARKETING CONTENT */
/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center; }

.marketing h2 {
  font-weight: normal; }

.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px; }

/* Featurettes */
.featurette-divider {
  margin: 80px 0;
  /* Space out the Bootstrap <hr> more */ }

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px; }

/* RESPONSIVE CSS */
@media (min-width: 768px) {
  /* Navbar positioning */
  .navbar-wrapper {
    margin-top: 20px; }
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px; }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0; }
  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px; }
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.4; }
  .featurette-heading {
    font-size: 50px; } }

@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 120px; } }

/* Contact page */
.contact-header-container {
  max-width: 800px;
  color: #333; }

.contact-container {
  color: #333; }

.contact-text-alignment {
  text-align: center; }

.header {
  color: #333;
  font-size: 27px;
  padding: 10px; }

.bigicon {
  font-size: 35px;
  color: #333; }

.background-contact-form {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: grey;
  color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.Wysiwyg-text-image__text p {
  margin: 0 0 5px;
  font-family: 'Roboto'; }

/*
 * Globals
 * Onderaan de pagina staan alle eigengemaakte classes
 */
/* Links */
/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none;
  /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff; }

/* Fullpage */
#fullpage {
  display: block;
  width: 100%;
  height: 100vh; }

.about_welcome {
  background: #333;
  height: 100%;
  width: 100%;
  position: absolute; }

.horizontal-align {
  color: #fff; }

.arrow-down {
  position: relative;
  text-align: center;
  font-size: 24px;
  top: 95%;
  color: #fff;
  z-index: 5; }

@media (max-width: 768px) {
  .arrow-down {
    top: 92.5%; } }

/*
 * Base structure
 */
/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper-gallerypages {
  color: #fff;
  text-align: center;
  display: table;
  width: 100%;
  height: 100%;
  /* For at least Firefox */
  min-height: 100%;
  background-color: #fff; }

.cover-container {
  margin-right: auto;
  margin-left: auto; }

.masthead {
  position: absolute;
  color: #fff;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 10; }
  .masthead a {
    color: white; }

/* Padding for spacing */
.inner {
  padding: 30px; }

/*
 * Header
 */
/* Responsive Nav */
.masthead-brand {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10%; }

.masthead-nav {
  margin-right: 10%;
  float: right; }

.masthead-nav > li {
  display: inline-block; }

.masthead-nav > li + li {
  margin-left: 20px; }

.masthead-nav > li > a {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  /* IE8 proofing */
  color: rgba(255, 255, 255, 0.75);
  border-bottom: 2px solid transparent;
  padding: 5px 10px; }

.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #a9a9a9;
  border-bottom-color: rgba(255, 255, 255, 0.25); }

.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  color: #fff;
  border-bottom-color: #fff; }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: #fff; }

@media (min-width: 768px) {
  .masthead-brand {
    float: left; } }

/*
 * Cover
 */
.cover {
  padding: 0 20px; }

.cover .btn-lg {
  margin-top: 75px;
  padding: 10px 20px;
  font-weight: bold; }

/*
 * Footer
 */
.mastfoot {
  color: #999;
  /* IE8 proofing */
  color: rgba(255, 255, 255, 0.5); }

/*
 * Affix and center
 */
@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    margin-top: 0;
    width: 100%; }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle; }
  /* Handle the widths */ }

/* Eigengemaakte Classes */
.Masthead__menu-items__item__link {
  color: #3c3b3e; }
  .Masthead__menu-items__item__link:hover {
    color: #3c3b3e;
    text-decoration: none; }

/* Gallery */
.image-gallery-information {
  text-align: left; }

/* Small Images */
.small-images-gallery {
  margin-top: -50px; }

@media (max-width: 360px) {
  .gallerypages {
    max-width: 300px; } }

/* Button Next to Small Images */
.gallery-button {
  text-align: left;
  margin-top: -50px; }

/* Little Responsive edit on the Button */
@media (max-width: 768px) {
  .gallery-button {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center; } }

/* Bootstrap table */
.gallery-text {
  font-size: 16px; }

.table-gallery {
  font-size: 10px;
  color: white;
  background-color: grey; }

.floating-pictures {
  margin-top: -90px;
  position: relative;
  z-index: 1; }

/* Homepage */
/* Sticky Footer Scooped*/
body {
  background-color: #ffffff; }

.masthead-brand {
  margin-top: 4px; }

a:hover {
  text-decoration: none; }

.page-header-container {
  margin-top: 25px;
  max-width: 800px; }

.Utils--Center--Align {
  text-align: center; }

.page-header-content {
  margin-top: 20%;
  text-align: center; }

.page-content {
  text-align: center; }

.more-about-us-div {
  text-align: center;
  margin-top: 150px; }

.more-about-us {
  width: auto;
  margin-top: 150px;
  font-size: 22px;
  color: #0000ed;
  border-bottom-color: #0000ed;
  border-bottom: 2px solid; }

/* IcoMOON icons */
.icon-share:before {
  content: "\e900"; }

.icon-catalogus:before {
  content: "\e0e0"; }

.icon-share {
  color: white;
  margin-right: 25px;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

/* Marketing Featurettes */
.gallerypages {
  margin-top: 150px;
  margin-bottom: 100px; }

.gallerypagescontent {
  color: #333;
  border: 2px solid #333;
  padding-left: 0;
  padding-right: 0; }

/*gallery */
.image-with-bar {
  margin-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto; }

.image-info {
  padding: 15px 20px;
  background: #333;
  font-family: DecimaNova, Arial, sans-serif;
  color: grey;
  max-width: 360px; }

@media (max-width: 991px) {
  .hidden--search {
    display: none; } }

@media (max-width: 991px) {
  .show--search {
    display: block; } }

@media (max-width: 360px) {
  .cover-heading {
    margin-top: 70%;
    font-size: 28px; } }

@media (max-width: 768px) {
  .cover-heading {
    margin-top: 50%; }
  .image-with-bar {
    text-align: center; }
  .image-info {
    margin-left: auto;
    margin-right: auto; } }

.image-info h3 {
  margin-top: 0; }

/* Carousel slider */
#myCarousel {
  margin-top: 50px; }

.carousel-text-container {
  width: 70%; }

.Footer__container__item {
  text-align: left !important;
  color: #333; }

.Footer__container__menu__link {
  color: #333; }

.Footer__container__menu__register {
  margin-bottom: 20px;
  background-color: #222222;
  padding: 20px;
  font-size: 20px; }

/* Content page */
.banner-content-button {
  text-align: center !important; }

.bannerjumbotron {
  top: 100px;
  position: relative;
  background-color: transparent;
  background-image: url("/images/hvdk.png");
  background-repeat: no-repeat;
  background: cover;
  height: 600px;
  width: 100%;
  margin: 0 auto;
  resize: both;
  margin-bottom: 200px; }

.jumbotroncolor {
  padding: 20px;
  resize: both;
  margin: 0 auto;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  height: 600px; }

.bannerjumbotron h2, .bannerjumbotron p {
  text-align: center;
  color: #fff;
  width: 100%; }

.bannerjumbotron p {
  position: relative;
  margin-bottom: 50px; }

.bannerjumbotron h2 {
  margin-top: 7.5%; }

@media (max-width: 768px) {
  .bannerjumbotron {
    height: 300px; }
  .jumbotroncolor {
    height: 400px; } }

@media (max-width: 1024px) {
  .bannerjumbotron {
    height: 400px; }
  .jumbotroncolor {
    height: 400px; } }

.banner-content-button {
  margin: 0;
  background: white;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.contenth2 {
  right: 5% !important;
  bottom: 5% !important; }

/* Fullpage image */
.fullpagecover {
  height: 100vh;
  resize: both;
  margin: 0 auto;
  position: relative; }

.fullpageimage-image {
  height: 100vh; }

@media (max-width: 1024px) {
  .fullpagecover {
    height: 75vh; }
  .fullpageimage-image {
    height: 75vh; } }

@media (max-width: 768px) {
  .fullpagecover {
    height: 50vh; }
  .fullpageimage-image {
    height: 50vh; } }

@media (max-width: 480px) {
  .fullpagecover {
    height: 30vh; }
  .fullpageimage-image {
    height: 30vh; } }

.arrow-container {
  min-height: 20px;
  text-align: center; }

@keyframes moving {
  0% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0);
    color: transparent; } }

@-webkit-keyframes moving {
  0% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0);
    color: transparent; } }

.moving {
  -webkit-animation: moving 2s infinite;
  animation: moving 2s infinite; }

.pictures-column-container {
  position: relative;
  width: 100%;
  height: 450px; }

.pictures-column-container .container {
  height: 100%; }

.gallery-row {
  padding-bottom: 30px; }

.carousel-buttons {
  position: absolute;
  top: 0;
  bottom: 0; }

.carousel-right {
  right: 100px; }

.carousel-left {
  left: 100px; }

/*
* Utils
*/
.Utils__alignment--center {
  text-align: center; }

.Utils__alignment--left {
  text-align: left; }

.Utils__alignment--right {
  text-align: right; }

.Utils__float--left {
  float: left; }

.Utils__float--right {
  float: right; }

/**
 * Epic vertical container, only requirement is that the parent of this util has a position relative or absolute : )
 */
.Utils__vertical-container {
  width: 100%;
  height: 100%; }

.Utils__vertical-container__content {
  width: 100%;
  height: 100%;
  display: table;
  -webkit-backface-visibility: hidden; }

.Utils__vertical-align--middle {
  display: table-cell;
  vertical-align: middle; }

.Utils__horizontal-align--center {
  text-align: center; }

.Utils__background-size--cover {
  background-size: cover; }

.Utils__display--flex {
  display: flex; }

.Utils__display--none {
  display: none; }

.Utils__opacity--none {
  opacity: 0; }

.Utils__flex__horizontal--center {
  justify-content: center; }

.Utils__flex__vertical--center {
  align-items: center; }

.Utils__flex__vertical--start {
  align-self: flex-start; }

.Utils__flex__vertical--end {
  align-self: flex-end; }

.Utils__display-flex--1 {
  flex: 1; }

.Utils__display-flex--2 {
  flex: 2; }

.Utils__display-flex--3 {
  flex: 3; }

.Utils__display-flex--4 {
  flex: 4; }

.Utils__display-flex--6 {
  flex: 6; }

.Utils__display-flex--8 {
  flex: 8; }

.Utils__display-flex--10 {
  flex: 10; }

.Utils__display-flex--12 {
  flex: 12; }

.Utils__menu__offset {
  padding-top: 70px; }

.Utils__absolute {
  position: absolute; }

/* 
* Global styling
*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?jzd1rw");
  src: url("../fonts/icomoon.eot?jzd1rw#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?jzd1rw") format("woff2"), url("../fonts/icomoon.ttf?jzd1rw") format("truetype"), url("../fonts/icomoon.woff?jzd1rw") format("woff"), url("../fonts/icomoon.svg?jzd1rw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-scrollIndicator:before {
  content: "\e900"; }

.icon-arrowRight:before {
  content: "\e901"; }

.icon-quote:before {
  content: "\e902"; }

body {
  font-family: 'Open Sans', sans-serif;
  padding-top: 64px; }

html {
  -webkit-font-smoothing: antialiased;
  font-weight: 400; }

.last_child {
  margin-bottom: 100px !important; }

.icon-close--navigation:before {
  content: "\e14c";
  color: #fff;
  font-family: 'icomoon';
  font-size: 20px;
  position: relative;
  top: -2px; }
  @media (max-width: 991px) {
    .icon-close--navigation:before {
      top: -5px; } }

.icon-search--navigation:before {
  content: "\e8b6";
  color: #fff;
  font-family: 'icomoon';
  font-size: 20px;
  position: relative;
  top: -2px; }

/*
* Masthead
*/
.Transition--Color {
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear; }

.Transition--Background-Color {
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out; }

.Effect--fading {
  -webkit-animation: SetOpacityBG 1s ease-out;
  -moz-animation: SetOpacityBG 1s ease-out;
  -o-animation: SetOpacityBG 1s ease-out;
  animation: SetOpacityBG 1s ease-out;
  background-color: white; }

@keyframes SetOpacityBG {
  0% {
    background-color: rgba(255, 255, 255, 0); }
  100% {
    background-color: white; } }

/*
* Floating images
*/
.Floating-images__row__content__hover-overlay {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

.Floating-images__row__content__subtitle {
  -o-transition: opacity .2s ease-out, background 0.2s ease-out;
  -ms-transition: opacity .2s ease-out, background 0.2s ease-out;
  -moz-transition: opacity .2s ease-out, background 0.2s ease-out;
  -webkit-transition: opacity .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: opacity .2s ease-out, background 0.2s ease-out; }

/*
* Title text button column
*/
.Information-column-container__button {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

/*
* Title images column
*/
.Title-images-column--hoverstate {
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: all 0.2s ease-out; }

/*
* News block
*/
.News-block__row__item__text {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

.slideout-panel-darkness, .slideout--searchbg {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

.slideout--searchbg--fadeOut {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

.Catalogus__block:hover .icon-catalogus {
  -o-transition: opacity .2s linear;
  -ms-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -webkit-transition: opacity .2s linear;
  /* ...and now override with proper CSS property */
  transition: opacity .2s linear; }

/*
* More information hover
*/
.pic-text .pic-text__button:hover .pic-text__more, .pic-text .pic-text__button:hover .icon-arrow-right:before {
  -o-transition: opacity .2s linear;
  -ms-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -webkit-transition: opacity .2s linear;
  /* ...and now override with proper CSS property */
  transition: opacity .2s linear; }

.about_us .Photo-swipe-gallery__image--hover {
  -o-transition: opacity .2s linear;
  -ms-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -webkit-transition: opacity .2s linear;
  /* ...and now override with proper CSS property */
  transition: opacity .2s linear; }

.news_overview .Pagination__pager--link:hover {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out; }

body {
  text-rendering: optimizeLegibility; }

/*
* Components
*/
.News__overview {
  margin-bottom: 200px; }

.News__overview__block__header {
  margin-top: -90px;
  border-radius: 4px;
  background-size: cover;
  max-height: 320px;
  width: 100%;
  z-index: 2; }

.News__overview__block__header--image {
  border-radius: 4px;
  max-height: 320px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  object-fit: cover;
  z-index: 1; }

.News__overview__block__text {
  margin-top: 10px; }

.News__overview__block__text--header {
  color: #000;
  font-size: 30px;
  font-family: 'Open Sans';
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 41px;
  margin-bottom: 30px; }

.News__overview__block__text--text-block {
  font-size: 20px;
  font-family: 'Open Sans';
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.9); }

.News__overview__block__button--button {
  margin-top: 20px; }

.Pic-text {
  height: 100%;
  position: relative; }
  .Pic-text .Pic-text__holder {
    display: flex;
    flex-direction: row; }
    @media (max-width: 667px) {
      .Pic-text .Pic-text__holder {
        flex-direction: column-reverse; }
        .Pic-text .Pic-text__holder .Utils__vertical-align {
          height: 100%; } }
  .Pic-text .Pic-text__image {
    min-height: 160px;
    width: 100%;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (max-width: 991px) {
      .Pic-text .Pic-text__image {
        min-height: 220px;
        padding-left: 15px;
        padding-right: 15px; } }
    @media (max-width: 667px) {
      .Pic-text .Pic-text__image {
        min-height: 300px;
        margin-top: 25px; } }
    @media (max-width: 320px) {
      .Pic-text .Pic-text__image {
        min-height: 235px; } }
  .Pic-text .col-md-4 {
    padding: 0; }
  .Pic-text .col-md-8 {
    padding-left: 30px;
    padding-right: 0px;
    text-align: left; }
    @media (max-width: 992px) {
      .Pic-text .col-md-8 {
        padding-left: 15px; } }
    @media (max-width: 667px) {
      .Pic-text .col-md-8 {
        padding-left: 0px; } }
  .Pic-text .Pic-text__title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    width: 90%; }
  .Pic-text .Pic-text__text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.9);
    max-height: 100px;
    overflow: hidden; }
  .Pic-text .Pic-text__text .Utils__vertical-align {
    text-align: left; }
  .Pic-text .Pic-text__more {
    height: auto;
    font-size: 14px;
    color: #000000;
    font-style: italic;
    opacity: 0.6; }
    @media (max-width: 768px) {
      .Pic-text .Pic-text__more {
        margin-bottom: 20px; } }
  .Pic-text .Pic-text__button:hover .Pic-text__more, .Pic-text .Pic-text__button:hover .icon-arrow-right:before {
    opacity: 1; }
  .Pic-text .Pic-text__ID--block {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2b3e6b;
    width: auto;
    padding: 5px 10px;
    height: 30px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px; }
    @media (max-width: 992px) {
      .Pic-text .Pic-text__ID--block {
        margin-top: 6px;
        position: relative;
        margin-bottom: 10px; } }
  .Pic-text .icon-arrow-right:before {
    top: 2px;
    content: "\e315";
    font-family: 'icomoon';
    color: #000;
    position: relative;
    font-size: 18px;
    opacity: 0.6; }

.Product__seperator {
  margin-top: 30px;
  margin-bottom: 30px; }

.Pic-text--last-child {
  margin-bottom: 100px; }

.Title-images-column {
  text-transform: uppercase; }

.Title-images-column__content {
  margin-top: 100px;
  margin-bottom: 100px; }

.Title-images-column__header-text {
  margin-bottom: 40px; }

.Title-images-column__row__image {
  position: relative;
  height: 184px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .Title-images-column__row__image {
      margin-bottom: 15px; } }

.Title-images-column .col-md-3 {
  border-radius: 4px; }

.Title-images-column--hoverstate:hover {
  cursor: pointer;
  border-radius: 4px; }

/*
* Responsive
*/
@media (max-width: 992px) {
  .Title-images-column {
    height: 100%; } }

@media (max-width: 768px) {
  .Title-images-column__row__image {
    height: 160px; } }

/**
 * extends components/columns/title-text-column.scss
 */
.Information-column-container__button {
  padding: 9px 47px; }

.Information-column-container__button {
  margin-top: 30px; }

.Information-column-container {
  text-align: center;
  margin-top: 100px;
  padding-top: 0;
  margin-bottom: 100px; }

.Information-column-container__header-text {
  margin-bottom: 30px;
  margin-top: 0;
  text-transform: uppercase; }

.Information-column-container__text-holder {
  margin-bottom: 50px; }

.Title__text__column--background-color {
  background-color: #f0eff1;
  width: 100%;
  padding: 25px; }

/*
* Responsive
*/
@media (max-width: 768px) {
  .Information-column-container__text-holder {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%; }
  .Information-column-container__button {
    padding-left: 47px;
    padding-right: 47px; }
  .Information-column-container__text-holder {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

.HeaderFullWidth {
  width: 100%;
  height: 81vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

/*
* Responsive
*/
@media (max-width: 1199px) {
  .HeaderFullWidth {
    height: 506px; } }

@media (max-width: 767px) {
  .HeaderFullWidth {
    height: 540px; } }

@media (max-width: 320px) {
  .HeaderFullWidth {
    height: 375px; } }

/**
 * extends components/headers/background_image.scss
 */
.HeaderFullWidthInner {
  display: table;
  height: 100%;
  width: 100%; }

.HeaderFullWidth__title {
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 4.1px;
  height: 100%;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: -60px; }
  @media (max-width: 380px) {
    .HeaderFullWidth__title {
      margin-top: -30px; } }

/*
* Oude media queries
*/
.HeaderFullWidth__title--holder {
  width: 900px;
  margin: 0 auto; }
  @media (max-width: 991px) {
    .HeaderFullWidth__title--holder {
      width: 700px; } }
  @media (max-width: 768px) {
    .HeaderFullWidth__title--holder {
      width: 500px;
      margin-top: 50px; } }
  @media (max-width: 500px) {
    .HeaderFullWidth__title--holder {
      width: 400px; } }
  @media (max-width: 380px) {
    .HeaderFullWidth__title--holder {
      width: 320px; } }
  @media (max-width: 320px) {
    .HeaderFullWidth__title--holder {
      width: 300px; } }

.HeaderFullWidth__title--h1 {
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 86px;
  line-height: 101px;
  letter-spacing: 0px;
  text-transform: none;
  font-weight: bold;
  margin-bottom: 0px; }
  @media (max-width: 767px) {
    .HeaderFullWidth__title--h1 {
      line-height: 50px;
      font-size: 40px; } }
  @media (max-width: 380px) {
    .HeaderFullWidth__title--h1 {
      font-size: 45px; } }

.HeaderFullWidth__subtitle {
  width: 900px;
  font-size: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  font-style: italic;
  font-weight: 400;
  font-family: 'Merriweather', serif; }
  @media (max-width: 991px) {
    .HeaderFullWidth__subtitle {
      font-size: 30px;
      font-style: normal;
      position: relative;
      top: 20px; } }

/*
* Responsive
*/
.search_results .Search-results {
  margin-top: 200px; }
  @media (max-width: 768px) {
    .search_results .Search-results {
      margin-top: 100px; } }

body {
  width: 100%;
  height: 100%; }

.slideout-menu {
  background-color: #c3122f;
  position: fixed;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: none;
  box-shadow: -1px 0px 15px 0px rgba(0, 0, 0, 0.5); }

.fa-angle-down {
  margin-top: 10px; }

#slideout-menu--languages {
  background-color: #c3122f;
  position: absolute;
  right: -275px;
  top: 0;
  width: 275px;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  display: none;
  z-index: 5; }

.slideout-menu--open--languages:hover {
  cursor: pointer; }

.slideout-panel-darkness {
  position: absolute;
  z-index: 1000;
  width: 102%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); }

.icon-menu:before {
  content: "\e5d2";
  font-family: icomoon;
  font-size: 30px;
  color: #ffffff; }

.icon-menu {
  margin-top: -10px;
  float: left;
  padding-right: 15px; }

.icon-menu:hover {
  cursor: pointer; }

.slideout-panel {
  position: relative;
  z-index: 3;
  background-color: #ffffff; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden; }

.slideout-open .slideout-menu {
  display: block; }

.slideout-menu__header {
  width: 100%;
  font-size: 15px;
  color: #ffffff;
  text-align: right;
  padding-right: 15px;
  height: 50.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  text-transform: uppercase; }

.slideout-menu__bottom {
  text-transform: uppercase;
  font-size: 15px;
  color: #ffffff;
  text-align: right;
  padding-right: 15px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.3); }

.mobile-back {
  margin-right: 5px; }

.icon-close {
  font-size: 14px;
  vertical-align: -1px;
  position: relative;
  font-weight: 400; }

.icon-close:hover {
  cursor: pointer; }

.slideout-menu__items {
  text-decoration: none;
  width: 100%;
  text-align: right;
  padding-right: 15px;
  text-transform: uppercase; }

.slideout-menu__item {
  list-style-type: none;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1px; }

.slideout-menu__item__link {
  word-wrap: break-word;
  color: white; }
  .slideout-menu__item__link:hover {
    color: white;
    text-decoration: none; }

.slideout-menu__item:last-child {
  padding-bottom: 70px; }

.slideout-menu__item:hover {
  color: #ffa726; }

.slideout__menu__item--active {
  color: #ffa726; }

.Pagination {
  margin-top: -100px; }

.Pagination__pager {
  color: #2b3e6b; }

.Pagination__pager--older {
  float: left; }

.Pagination__pager--newer {
  float: right; }

.Pagination__pager--text {
  position: relative;
  top: -5.5px; }

.Pagination__pager .Pagination__pager--older .Pagination__pager--link,
.Pagination__pager .Pagination__pager--newer .Pagination__pager--link {
  border: none;
  padding: 5px 10px 5px 25px;
  border-radius: 4px; }

.Pagination__pager .Pagination__pager--older .Pagination__pager--link {
  padding: 5px 25px 5px 10px; }

.Pagination__pager--text-left {
  margin-left: 10px; }

.Pagination__pager--text-right {
  margin-right: 10px; }

.Button__dropdown {
  margin-bottom: 100px; }

.Button__dropdown__menu {
  z-index: 0;
  margin: 20px; }

.Button__dropdown__menu__item {
  text-align: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background-color: #c3122f;
  text-transform: none;
  border-radius: 100px;
  margin-top: 0px;
  text-align: center;
  border: 0px;
  width: 175px;
  padding: 10px 25px; }
  @media (max-width: 767px) {
    .Button__dropdown__menu__item {
      margin-top: 10px;
      margin-left: 0px;
      width: 200px; } }

.Button__dropdown__menu__item:hover {
  background-color: #ac1029;
  color: #fff; }

.Button__dropdown__menu__item__text {
  pointer-events: auto; }
  @media (min-width: 769px) {
    .Button__dropdown__menu__item__text {
      padding: 0px 10px; } }

.Button__dropdown__menu__item__icon {
  height: 20px;
  width: 20px;
  margin-right: 5px; }

.Button__dropdown__menu > li > a {
  padding: 15px;
  width: 100%; }

.Information-column-container__button {
  pointer-events: auto; }

.Catalogus__row {
  margin-top: 65px;
  margin-bottom: 50px; }

.Catalogus__row--after-first-row {
  margin-top: 5px; }

.Catalogus__block {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #f0eff1;
  height: 250px;
  margin-top: 30px;
  margin-right: 30px;
  max-width: 350px; }

.Catalogus__block__row--title {
  text-transform: uppercase;
  color: #000;
  letter-spacing: 2px;
  font-size: 30px;
  font-family: 'Open Sans';
  font-weight: 500; }

.Catalogus__block--text {
  font-size: 20px;
  font-family: 'Open Sans';
  color: #000; }

.Catalogus__block--icon {
  width: 100%;
  margin-top: 17.5%; }
  @media (max-width: 991px) {
    .Catalogus__block--icon {
      margin-top: 20%; } }
  @media (max-width: 768px) {
    .Catalogus__block--icon {
      margin-top: 17.5%; } }
  @media (max-width: 320px) {
    .Catalogus__block--icon {
      margin-top: 20%; } }

.Contact__form {
  min-height: 630px;
  height: auto;
  margin-bottom: 100px;
  margin-top: -90px; }
  @media (max-width: 992px) {
    .Contact__form {
      height: auto; } }
  @media (max-width: 320px) {
    .Contact__form {
      height: auto; } }

.Contact--min--height {
  min-height: 670px;
  height: auto; }

.Contact__form--shadow {
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 4px; }

.Contact__form__input-fields {
  height: 45px; }

.Contact__form__input-fields, .Contact__form__content__left__form--message--textarea {
  font-size: 20px;
  color: #000;
  font-weight: 300; }

@media (max-width: 768px) {
  .Contact__form__content__right {
    left: -15px; } }

.Contact__form__content__right--title, .Contact__form__content__left--title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 52px;
  color: #000; }
  @media (max-width: 1200px) {
    .Contact__form__content__right--title, .Contact__form__content__left--title {
      font-size: 26px; } }
  @media (max-width: 992px) {
    .Contact__form__content__right--title, .Contact__form__content__left--title {
      font-size: 24px; } }
  @media (max-width: 768px) {
    .Contact__form__content__right--title, .Contact__form__content__left--title {
      font-size: 22px; } }
  @media (max-width: 320px) {
    .Contact__form__content__right--title, .Contact__form__content__left--title {
      font-size: 19px; } }

.Contact__form__content__left__form--message--textarea {
  min-height: 135px !important; }

.Contact__form__content__left__form--submit--button {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background-color: #ffa726; }

.Contact__form__content__left__form--submit--button:hover {
  background-color: #ff9800;
  color: #fff; }

.Contact__form__content__right--font-light {
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Roboto';
  font-weight: 300;
  margin-top: -5px; }

.Contact__form__content__right--contact-information {
  font-weight: 400;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 2.3;
  font-family: 'Open Sans'; }
  @media (max-width: 768px) {
    .Contact__form__content__right--contact-information {
      font-size: 19px; } }
  @media (max-width: 320px) {
    .Contact__form__content__right--contact-information {
      font-size: 16px; } }

.Contact__form__content__right--contact-information:hover {
  color: rgba(0, 0, 0, 0.9); }

/*
* Bootstrap classes override
*/
.Contact__form__content__left, .Contact__form__content__right, .Contact__form {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px; }

.Contact__form__content__left--title {
  margin-left: -15px; }

.Floating-images {
  min-height: 340px;
  margin-top: -90px;
  margin-bottom: 0px; }
  @media (max-width: 991px) {
    .Floating-images {
      min-height: 260px;
      margin-top: -30px; } }
  @media (max-width: 767px) {
    .Floating-images {
      margin-bottom: 50px; } }

.Floating-images__row {
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .Floating-images__row {
      margin-bottom: 0px; } }

@media (max-width: 991px) {
  .Services .Floating-image__row {
    margin-top: 30px; } }

@media (max-width: 992px) {
  .Floating-images--responsive {
    margin-bottom: 20px; } }

.Floating-images__row__content {
  height: 320px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.Floating-images__row__content--title {
  text-transform: uppercase; }

.Floating-images__row__content__hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1); }

.Floating-images__row__content__subtitle {
  position: absolute;
  bottom: 30px;
  text-transform: none;
  left: 0;
  text-align: center;
  width: 100%; }

.Floating-images__row__content:hover {
  cursor: pointer; }

/*
* Responsive
*/
@media (max-width: 768px) {
  .Floating-images__row__content {
    height: 260px; } }

@media (max-width: 360px) {
  .Floating-images__row__content {
    height: 160px; } }

.Full-width__container {
  width: 100%; }

.Full-width__container--image {
  width: 100%; }

.Moving-arrow__icon {
  font-size: 40px;
  color: black;
  width: 100%;
  text-align: center; }

.Moving-arrow {
  margin-top: 20px; }

.News-block {
  margin-bottom: 70px; }
  @media (max-width: 767px) {
    .News-block {
      margin-bottom: 50px; } }

.News-block__header-text {
  margin-bottom: 40px;
  margin-top: 100px;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .News-block__header-text {
      margin-top: 75px;
      margin-bottom: 30px; } }

.News-block__row {
  padding-bottom: 30px; }

.News-block__row__item {
  width: 100%;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 992px) {
    .News-block__row__item {
      margin-bottom: 15px; } }

.News-block__row__item:hover {
  cursor: pointer; }

.News-block__row__item__image {
  height: 180px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

.News-block__row__item__text__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-height: 36px;
  /* fallback */
  max-height: 110px;
  /* fallback */ }

.News-block__row__item__text {
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 20px;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

/*
* Responsive
*/
@media (max-width: 768px) {
  .News-block__row__item__text {
    padding-left: 10px;
    padding-top: 5px;
    height: 60px; }
  .News-block__row {
    padding-bottom: 0; }
  .News-block__row__item__image {
    height: 160px; } }

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y; }

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%; }

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-item {
  float: left; }

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer; }

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

#owl-demo .item img {
  display: block;
  width: 100%;
  height: 600px; }

#myCarousel {
  height: 600px; }
  @media (max-width: 768px) {
    #myCarousel {
      display: none; } }

#myCarousel .item {
  height: 600px; }

.carousel-inner__item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.myCarousel__inner__item__column {
  margin: 0 auto;
  text-align: center; }

.Photo-swipe-gallery__image__a__img--featured {
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 4px;
  max-height: 516px; }

.Photo-swipe-gallery__image__a__img--small {
  max-width: 100%;
  line-height: 50px;
  margin: auto;
  display: block; }

.Photo-swipe-gallery__image--small {
  border-radius: 4px;
  height: 65px;
  background-position: 100px;
  width: 13.187513228%;
  display: inline-block;
  margin-bottom: 8px;
  vertical-align: middle;
  text-align: center;
  margin-right: 0.896780557%;
  overflow: hidden; }
  @media (max-width: 991px) {
    .Photo-swipe-gallery__image--small {
      width: 100%;
      float: none;
      display: none; } }
  @media (max-width: 1199px) {
    .Photo-swipe-gallery__image--small {
      width: 13.1190647%; } }

.Photo-swipe-gallery__image--small:nth-child(8), .Photo-swipe-gallery__image--small:nth-child(16),
.Photo-swipe-gallery__image--small:nth-child(24), .Photo-swipe-gallery__image--small:nth-child(32),
.Photo-swipe-gallery__image--small:nth-child(40), .Photo-swipe-gallery__image--small:nth-child(48) {
  margin-right: 0px; }

#map {
  height: 500px;
  width: 100%; }
  @media (max-width: 767px) {
    #map {
      height: 300px; } }

.Team {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .Team {
      margin-top: 50px; } }

.Team__title {
  width: 100%;
  margin-bottom: 40px; }

.Team__title--text {
  color: #c2122e;
  font-size: 28px;
  letter-spacing: 0.5px;
  font-family: 'Roboto', sans-serif; }

.Team-holder {
  padding-left: 15px; }
  @media (max-width: 991px) {
    .Team-holder {
      padding-right: 15px; } }
  @media (max-width: 767px) {
    .Team-holder {
      padding-left: 30px; } }

.Team-list {
  display: block;
  height: auto;
  float: left;
  border-radius: 6px;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .Team-list {
      padding-left: 0px;
      margin-bottom: 15px; } }

.Team-list__member {
  position: relative;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }

.Team-list__member--info {
  height: 100%; }

.Team-list__member--text-info {
  padding: 20px; }

.Team-list__member--image {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover;
  width: 100%;
  height: 300px;
  transition: all .4s ease-out .1s;
  -moz-transition: all .4s ease-out .1s;
  -webkit-transition: all .4s ease-out .1s; }
  @media (max-width: 991px) {
    .Team-list__member--image {
      height: 240px; } }
  @media (max-width: 767px) {
    .Team-list__member--image {
      height: 500px; } }
  @media (max-width: 600px) {
    .Team-list__member--image {
      height: 375px; } }
  @media (max-width: 480px) {
    .Team-list__member--image {
      height: 250px; } }

.Team-list__member--function {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #c3122f;
  margin-bottom: 10px;
  margin-top: 0px;
  line-height: 12px;
  opacity: 1;
  position: relative;
  left: 2px;
  transition: all .2s ease-out .1s;
  -moz-transition: all .2s ease-out .1s;
  -webkit-transition: all .2s ease-out .1s; }
  @media (max-width: 991px) {
    .Team-list__member--function {
      line-height: 15px; } }

.Team-list__member--name {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #1D1D1D;
  opacity: 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  transition: all .2s ease-out .1s;
  -moz-transition: all .2s ease-out .1s;
  -webkit-transition: all .2s ease-out .1s; }
  @media (max-width: 991px) {
    .Team-list__member--name {
      font-size: 22px; } }

.Cookies {
  position: fixed;
  bottom: 0;
  z-index: 9001;
  width: 100%;
  padding: 2em;
  background-color: rgba(64, 64, 64, 0.8);
  text-align: center;
  color: white;
  text-shadow: 0 0 4px #000; }

.Cookies__accept-button {
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  color: #fff; }
  .Cookies__accept-button:hover {
    background-color: #fff; }
  .Cookies__accept-button:focus {
    color: #fff; }
  .Cookies__accept-button:hover {
    color: #000; }

/*
* Client-specific styling
*/
.TextColumn {
  width: 100%; }

.TextColumn__Text {
  font-family: 'Roboto', 'sans-serif';
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: #3c3b3e;
  font-weight: 300;
  margin: 50px 0 30px 0; }

.VideoHeader {
  width: 100%;
  background-color: rgba(235, 235, 236, 0.5); }

.VideoHeader__container {
  position: relative; }
  .VideoHeader__container:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%; }
    @media (max-width: 767px) {
      .VideoHeader__container:before {
        padding: 0; } }
  .VideoHeader__container > .VideoHeader__VideoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    @media (max-width: 767px) {
      .VideoHeader__container > .VideoHeader__VideoContainer {
        width: 100%;
        position: relative; } }
  @media (max-width: 991px) {
    .VideoHeader__container {
      padding-left: 0;
      margin: 0;
      width: 100%;
      padding-right: 0; } }
  @media (max-width: 767px) {
    .VideoHeader__container {
      float: left;
      position: relative;
      float: left;
      width: 100%; } }

.VideoHeader__VideoContainer {
  display: block;
  width: 100%;
  height: 100%;
  margin-left: -55px; }
  @media (max-width: 991px) {
    .VideoHeader__VideoContainer {
      margin-left: 0;
      width: calc(100% - 105px); } }
  @media (max-width: 767px) {
    .VideoHeader__VideoContainer {
      margin: 0; } }

.VideoHeader__VideoContainer__Video {
  width: 100%;
  height: 100%; }

.VideoHeader__CalToAction {
  width: 210px;
  height: 210px;
  right: -35px;
  background-color: #00b4ff;
  position: absolute;
  top: 0;
  padding: 20px;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.8px; }
  .VideoHeader__CalToAction:hover {
    color: #fff;
    background: #00A2E5; }
  @media (max-width: 991px) {
    .VideoHeader__CalToAction {
      right: 0; } }
  @media (max-width: 767px) {
    .VideoHeader__CalToAction {
      width: calc(100% - 10px);
      float: left;
      height: auto;
      position: relative;
      margin: 0 0 0 5px;
      margin-bottom: 45px; } }

.VideoHeader__CalToAction__Text {
  font-size: 24px; }

.VideoHeader__CalToAction__SubText {
  margin-top: 50px;
  width: 100%;
  text-align: right; }

.VideoHeader__CalToAction__SubText__Icon {
  float: left; }

.MediaTextBlock {
  min-height: 500px;
  margin: 100px auto; }
  @media (max-width: 991px) {
    .MediaTextBlock {
      width: 100%;
      margin: 100px auto 0 0;
      padding-right: 25px; } }
  @media (max-width: 767px) {
    .MediaTextBlock {
      padding: 0 5px;
      margin: 0 auto 0 0;
      float: left; } }

.MediaTextBlock__row {
  display: block;
  height: 100%;
  margin-bottom: 80px; }
  @media (max-width: 991px) {
    .MediaTextBlock__row {
      margin: 0; } }

.MediaTextBlock__MediaContrainer, .MediaTextBlock__TextContrainer {
  display: block;
  height: 100%; }
  @media (max-width: 991px) {
    .MediaTextBlock__MediaContrainer, .MediaTextBlock__TextContrainer {
      padding-right: 0; } }
  @media (max-width: 767px) {
    .MediaTextBlock__MediaContrainer, .MediaTextBlock__TextContrainer {
      padding-left: 0; } }

.MediaTextBlock__MediaContainer__MediaBlock {
  display: block;
  width: calc(100% + 180px);
  margin-left: -55px;
  height: 500px;
  max-height: 100%;
  background-position: center;
  background-size: cover;
  z-index: 1; }
  @media (max-width: 991px) {
    .MediaTextBlock__MediaContainer__MediaBlock {
      width: calc(100% + 118px); } }
  @media (max-width: 767px) {
    .MediaTextBlock__MediaContainer__MediaBlock {
      width: 100%;
      margin: 0;
      padding-left: 0; } }

.MediaTextBlock__TextContainer__TextBlock {
  width: calc(100% - 90px);
  height: 366px;
  background: linear-gradient(rgba(60, 59, 62, 0.95), rgba(60, 59, 62, 0.95)), url("/front/images/asfalt.jpg");
  border-bottom: 10px solid #efa72d;
  padding: 35px 45px 35px 45px;
  color: #fff;
  z-index: 2;
  position: relative;
  right: -30px; }
  @media (max-width: 991px) {
    .MediaTextBlock__TextContainer__TextBlock {
      width: 100%;
      right: 0;
      height: auto; } }
  @media (max-width: 1200px) {
    .MediaTextBlock__TextContainer__TextBlock {
      height: auto; } }
  @media (max-width: 767px) {
    .MediaTextBlock__TextContainer__TextBlock {
      height: auto;
      padding-left: 20px;
      padding-right: 20px; } }

.MediaTextBlock__TextContainer__TextBlock__Title {
  font-size: 24px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif'; }

.MediaTextBlock__TextContainer__TextBlock__Title__Light {
  font-weight: 300; }

.MediaTextBlock__TextContainer__TextBlock__Title__Regular {
  font-weight: 400;
  margin-bottom: 45px; }
  @media (max-width: 991px) {
    .MediaTextBlock__TextContainer__TextBlock__Title__Regular {
      margin-bottom: 25px; } }

.MediaTextBlock__TextContainer__TextBlock__Text {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300;
  font-size: 18px;
  line-height: 1.7; }
  @media (max-width: 991px) {
    .MediaTextBlock__TextContainer__TextBlock__Text {
      font-size: 15px;
      line-height: 2.0; } }

.MediaTextBlock__TextContainer__TextBlock__Text__Dot {
  color: #00b4ff; }

.MediaTextBlock__TextContainer__LinkContainer {
  width: calc(100% - 60px);
  text-align: right;
  margin-top: 70px; }
  @media (max-width: 767px) {
    .MediaTextBlock__TextContainer__LinkContainer {
      width: 100%;
      text-align: center;
      margin-top: 65px;
      margin-bottom: 66px; } }

.MediaTextBlock__TextContainer__LinkContainer_Link {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase; }
  .MediaTextBlock__TextContainer__LinkContainer_Link:hover {
    color: #00A2E5; }
  @media (max-width: 767px) {
    .MediaTextBlock__TextContainer__LinkContainer_Link {
      border: 1px solid #00b4ff;
      padding: 16px 23px; } }

.MediaTextBlock__TextContainer__LinkContainer_Link__Icon {
  font-size: 12px;
  margin-right: 30px; }

.MediaTextBlock--AlignRight {
  padding-left: 25px; }
  @media (max-width: 991px) {
    .MediaTextBlock--AlignRight {
      padding-right: 0;
      margin: 100px 0 0 auto; } }
  @media (max-width: 767px) {
    .MediaTextBlock--AlignRight {
      padding-right: 5px;
      margin: 0 0 0 auto;
      padding-left: 5px; } }
  .MediaTextBlock--AlignRight .MediaTextBlock__MediaContainer__MediaBlock {
    margin-left: -130px; }
    @media (max-width: 991px) {
      .MediaTextBlock--AlignRight .MediaTextBlock__MediaContainer__MediaBlock {
        margin-left: -115px; } }
    @media (max-width: 767px) {
      .MediaTextBlock--AlignRight .MediaTextBlock__MediaContainer__MediaBlock {
        width: 100%;
        margin: 0; } }
  @media (max-width: 991px) {
    .MediaTextBlock--AlignRight .MediaTextBlock__TextContainer__TextBlock {
      width: calc(100% - 20px); } }
  @media (max-width: 767px) {
    .MediaTextBlock--AlignRight .MediaTextBlock__TextContainer__TextBlock {
      width: 100%; } }
  @media (max-width: 991px) {
    .MediaTextBlock--AlignRight .MediaTextBlock__TextContrainer {
      padding-left: 0; } }
  .MediaTextBlock--AlignRight .MediaTextBlock__TextContainer__LinkContainer {
    text-align: left;
    padding-left: 30px; }
    @media (max-width: 767px) {
      .MediaTextBlock--AlignRight .MediaTextBlock__TextContainer__LinkContainer {
        text-align: center;
        padding-left: 0; } }

.MediaTextBlock--intro {
  margin-top: 0; }
  @media (max-width: 767px) {
    .MediaTextBlock--intro {
      margin-bottom: 70px; } }
  .MediaTextBlock--intro .MediaTextBlock__TextContainer__TextBlock {
    border-bottom: none; }
  .MediaTextBlock--intro .MediaTextBlock__MediaContainer__MediaBlock {
    width: 100%;
    margin: 0;
    text-align: center; }
  .MediaTextBlock--intro .MediaTextBlock--intro__Image {
    margin: 66px 0 0 0;
    width: 271px; }

.MediaTextBlock--drive .MediaTextBlock__TextContainer__TextBlock__Text__Dot {
  color: #efa72d; }

.MediaTextBlock--coach .MediaTextBlock__TextContainer__TextBlock {
  border-color: #2c74ad;
  background: #ebebeb;
  color: #3c3b3e; }

.MediaTextBlock--coach .MediaTextBlock__TextContainer__TextBlock__Text__Dot {
  color: #2c74ad; }

.MediaTextBlock--repair .MediaTextBlock__TextContainer__TextBlock {
  border-color: #c62531; }

.MediaTextBlock--repair .MediaTextBlock__TextContainer__TextBlock__Text__Dot {
  color: #c62531; }

.MediaTextBlock__BackGround {
  background-color: rgba(235, 235, 236, 0.5);
  width: 100%;
  padding: 0 0 50px 0; }

.MediaTextBlock--outro {
  margin: 0 auto; }
  .MediaTextBlock--outro .MediaTextBlock__TextContainer__TextBlock {
    border-bottom: none; }
  .MediaTextBlock--outro .MediaTextBlock__TextContainer__LinkContainer_Link {
    background-color: #00b4ff;
    color: #fff;
    padding: 16px 23px;
    margin-left: -30px; }
    .MediaTextBlock--outro .MediaTextBlock__TextContainer__LinkContainer_Link:hover {
      background-color: #00A2E5; }
    @media (max-width: 767px) {
      .MediaTextBlock--outro .MediaTextBlock__TextContainer__LinkContainer_Link {
        margin: 0; } }

.NewsLetter {
  width: 100%;
  min-height: 138px;
  background-color: rgba(60, 59, 62, 0.1);
  padding-top: 45px; }
  @media (max-width: 991px) {
    .NewsLetter {
      margin-top: 100px; } }
  @media (max-width: 767px) {
    .NewsLetter {
      margin-top: 0;
      height: auto;
      padding: 57px 25px 68px 25px;
      float: left; } }

.NewsLetter__Text {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #3c3b3e; }
  @media (max-width: 991px) {
    .NewsLetter__Text {
      font-size: 15px;
      padding: 9px 0 0 20px; } }
  @media (max-width: 767px) {
    .NewsLetter__Text {
      margin-bottom: 45px;
      padding: 0; } }

.NewsLetter__Form {
  float: right; }
  @media (max-width: 991px) {
    .NewsLetter__Form {
      float: none; } }

input.NewsLetter__Form__Input {
  width: 345px;
  height: 60px;
  background-color: #ffffff;
  border: none;
  outline: none;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  color: #3c3b3e;
  padding: 20px;
  margin: 0 -3px 0 0; }
  @media (max-width: 991px) {
    input.NewsLetter__Form__Input {
      font-size: 15px;
      width: calc(100% - 61px); } }

.NewsLetter__Form__Button {
  width: 60px;
  height: 60px;
  background-color: #00b4ff;
  border: none;
  outline: none;
  color: #fff;
  position: relative;
  top: -1px; }
  .NewsLetter__Form__Button:hover {
    background-color: #00A2E5; }

.CustomInfo {
  width: 100%;
  min-height: 530px;
  background-color: rgba(235, 235, 236, 0.5);
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    .CustomInfo {
      height: auto;
      margin-bottom: 35px;
      float: left; } }

.CustomInfo__Container {
  display: block;
  height: 530px;
  max-height: 100%; }
  @media (max-width: 991px) {
    .CustomInfo__Container {
      padding: 0 30px; } }
  @media (max-width: 767px) {
    .CustomInfo__Container {
      height: auto; } }

.CustomInfo__Row {
  display: block;
  height: 530px;
  max-height: 100%;
  padding: 80px 0; }
  @media (max-width: 767px) {
    .CustomInfo__Row {
      height: auto;
      padding-top: 0px; } }

.CustomInfo__Column {
  display: block;
  height: 100%;
  padding: 0; }

.CustomInfo__TextBlock {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300; }

.CustomInfo__ImageContainer__TextBlock__Title {
  font-size: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .CustomInfo__ImageContainer__TextBlock__Title {
      font-size: 24px; } }

.CustomInfo__ImageContainer__TextBlock__Title__Regular {
  font-weight: 400; }

.CustomInfo__ImageContainer__TextBlock__Text {
  width: calc(100% - 90px);
  font-size: 18px;
  line-height: 1.7; }
  @media (max-width: 991px) {
    .CustomInfo__ImageContainer__TextBlock__Text {
      font-size: 15px;
      line-height: 2.0; } }
  @media (max-width: 767px) {
    .CustomInfo__ImageContainer__TextBlock__Text {
      width: 100%; } }

.CustomInfo__ImageContainer__Image {
  width: 100%;
  height: 100%;
  display: block;
  background-image: url("/front/images/afbeeldingCustomizen.jpg");
  background-size: cover;
  background-position: center center; }
  @media (max-width: 767px) {
    .CustomInfo__ImageContainer__Image {
      height: 250px;
      margin-bottom: 35px; } }

.CustomInfo__TextBlock__LinkContainer {
  width: calc(100% - 90px);
  margin-top: 50px; }
  @media (max-width: 767px) {
    .CustomInfo__TextBlock__LinkContainer {
      width: 100%;
      text-align: center; } }

.CustomInfo__TextBlock__LinkContainer__Link {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase; }
  .CustomInfo__TextBlock__LinkContainer__Link:hover {
    color: #00A2E5; }
  @media (max-width: 767px) {
    .CustomInfo__TextBlock__LinkContainer__Link {
      border: 1px solid #00b4ff;
      padding: 16px 23px; } }

.CustomInfo__TextBlock__LinkContainer__Link__Icon {
  font-size: 12px;
  margin-right: 30px; }

.QuoteSlider {
  min-height: 365px;
  position: relative;
  padding: 0;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .QuoteSlider {
      float: left; } }

.QuoteSlider__Row {
  display: block;
  min-height: 365px;
  position: absolute; }
  @media (max-width: 991px) {
    .QuoteSlider__Row {
      padding: 0 30px 0 30px; } }
  @media (max-width: 767px) {
    .QuoteSlider__Row {
      width: 100%;
      float: left !important;
      padding: 0 22px 0 52px; } }

.QuoteSlider__Row__Column {
  display: block;
  height: 100%;
  min-height: 365px;
  padding: 0; }
  @media (max-width: 991px) {
    .QuoteSlider__Row__Column {
      min-height: 0; } }

.QuoteSlider__ImageBlock {
  display: block;
  height: 100%;
  width: 100%;
  margin-left: -55px;
  position: relative;
  min-height: 365px; }
  @media (max-width: 991px) {
    .QuoteSlider__ImageBlock {
      width: 100%;
      margin: 0 0 65px 0; } }
  @media (max-width: 767px) {
    .QuoteSlider__ImageBlock {
      min-height: 200px;
      width: 100%;
      margin: 0 0 65px 0; } }

.QuoteSlider__ImageBlock__Image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1; }

.QuoteSlider__ImageBlock__Avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  margin: 40px -40px 0 0;
  border-radius: 50%;
  z-index: 2; }
  @media (max-width: 992px) {
    .QuoteSlider__ImageBlock__Avatar {
      right: 50%;
      bottom: -40px; } }

.QuoteSlider__ImageBlock__Avatar__Image {
  width: 100%;
  dispay: block;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%; }

.QuoteSlider__TextBlock {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300; }

.QuoteSlider__TextBlock__Title {
  font-size: 28px;
  letter-spacing: 0.2px;
  color: #3c3b3e;
  margin: -9px 0 37px 0; }

.QuoteSlider__TextBlock__Title__Job {
  font-weight: 400; }

.QuoteSlider__TextBlock__Content {
  font-style: italic;
  font-size: 18px;
  line-height: 1.7;
  color: #000000;
  position: relative;
  margin-left: 30px;
  padding-right: 100px; }
  @media (max-width: 767px) {
    .QuoteSlider__TextBlock__Content {
      padding-right: 0; } }

.QuoteSlider__TextBlock__Content__QuoteSign {
  font-size: 50px;
  margin: -30px 0 0 -30px;
  color: rgba(60, 59, 62, 0.1);
  position: absolute; }

.QuoteSlider__TextBlock__LinkContainer {
  margin-top: 80px; }
  @media (max-width: 991px) {
    .QuoteSlider__TextBlock__LinkContainer {
      margin-top: 37px; } }
  @media (max-width: 767px) {
    .QuoteSlider__TextBlock__LinkContainer {
      text-align: center;
      margin: 59px 0 65px 0; } }

.QuoteSlider__TextBlock__LinkContainer__Link {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase; }
  .QuoteSlider__TextBlock__LinkContainer__Link:hover {
    color: #00A2E5; }
  @media (max-width: 767px) {
    .QuoteSlider__TextBlock__LinkContainer__Link {
      border: 1px solid #00b4ff;
      padding: 16px 23px; } }

.QuoteSlider__TextBlock__LinkContainer__Link__Icon {
  font-size: 12px;
  margin-right: 30px; }

.ScrollIndicator {
  position: fixed;
  right: 30px;
  top: 740px;
  width: 30px;
  height: 30px;
  color: rgba(60, 59, 62, 0.2);
  font-size: 30px; }
  @media (max-width: 991px) {
    .ScrollIndicator {
      display: none; } }

.ContactForm {
  padding: 100px 0;
  width: 100%;
  background: rgba(235, 235, 236, 0.75); }

.ContactForm__Container {
  margin: 0 auto; }

.ContactForm__Text__Light {
  font-size: 28px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300;
  margin-bottom: 0; }

.ContactForm__Text__Regular {
  font-size: 28px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif';
  font-weight: 400;
  margin-bottom: 30px; }

.ContactForm__Container__FormInput {
  width: 100%;
  height: 60px;
  background-color: #fff;
  outline: none;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  color: #3c3b3e;
  padding: 20px;
  margin: 0 0 20px 0;
  border: none; }

.ContactForm__Container__FormInput--Message {
  height: 180px;
  resize: none; }

.ContactForm__Container__FormButton {
  background: #00b4ff;
  width: 100%;
  height: 60px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff; }
  .ContactForm__Container__FormButton:hover {
    background-color: #00A2E5; }

.ContactForm__Container__FormButton__Icon {
  margin-right: 30px; }

.about-us .HeaderFullWidth__title {
  margin-top: -30px; }

.about-us .pswp__caption__center {
  text-align: center;
  font-weight: 600; }

.about-us .Information-column-container__text-holder {
  margin-bottom: 30px; }

.about-us .Footer {
  margin-top: 70px; }
  @media (max-width: 991px) {
    .about-us .Footer {
      margin-top: 35px; } }

.about-us .Photo-swipe-gallery__image--hover {
  opacity: 0.90; }

.about-us .Photo-swipe-gallery__image--hover:hover {
  opacity: 1; }

.about-us .Half-page-rows:nth-child(even) .Information-column-container__padding {
  margin-left: 8.33333333%; }
  @media (min-width: 992px) and (max-width: 1440px) {
    .about-us .Half-page-rows:nth-child(even) .Information-column-container__padding {
      margin-left: 0px; } }
  @media (max-width: 991px) {
    .about-us .Half-page-rows:nth-child(even) .Information-column-container__padding {
      margin-left: 0px; } }

@media (min-width: 992px) {
  .about-us .Half-page-rows:nth-child(odd) .Information-column-container__padding {
    padding-right: 50px; } }

@media (max-width: 991px) {
  .about-us .Information-column-container__padding {
    padding: 0px; } }

@media (max-width: 767px) {
  .about-us .Information-column-container__padding {
    padding: 15px; } }

.about-us .Textblocks__container {
  margin-top: 20px; }
  @media (max-width: 767px) {
    .about-us .Textblocks__container {
      margin-top: 50px; } }

.about-us .Half-page-rows {
  margin-top: 80px;
  display: flex; }
  @media (max-width: 991px) {
    .about-us .Half-page-rows {
      margin-top: 30px; } }

@media (max-width: 991px) {
  .about-us .Half-page-rows:nth-child(odd) {
    flex-direction: column; } }

@media (max-width: 991px) {
  .about-us .Half-page-rows:nth-child(odd) .Half-page-image__container {
    padding-top: 30px; } }

@media (max-width: 991px) {
  .about-us .Half-page-rows:nth-child(even) .Half-page-text--even {
    padding-left: 0px; } }

@media (max-width: 991px) {
  .about-us .Half-page-rows:nth-child(even) .Half-page-image__container {
    padding-left: 0px;
    padding-top: 30px; } }

@media (max-width: 767px) {
  .about-us .Half-page-rows:nth-child(even) .Half-page-image__container {
    padding-left: 15px; } }

.about-us .Half-page-rows:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  left: -80px; }
  @media (min-width: 992px) and (max-width: 1440px) {
    .about-us .Half-page-rows:nth-child(even) {
      left: 0px; } }
  @media (max-width: 991px) {
    .about-us .Half-page-rows:nth-child(even) {
      flex-direction: column;
      margin-left: 0px;
      padding-left: 0px;
      left: 0px; } }

.about-us .Half-page-image__container {
  display: flex;
  flex: 1;
  min-height: 100%;
  align-items: center; }
  @media (max-width: 991px) {
    .about-us .Half-page-image__container {
      display: block;
      flex: none; } }
  @media (max-width: 767px) {
    .about-us .Half-page-image__container {
      padding-left: 30px;
      padding-right: 30px; } }

.contact .Masthead {
  top: 0; }

.contact .MediaTextBlock--contact {
  margin-bottom: 0; }

.downloads .Masthead {
  background-color: #2b3e6b; }

.downloads .Catalogus__block__row--title {
  font-size: 30px;
  font-weight: 600;
  font-family: 'Open Sans'; }

.downloads .Catalogus__block--text {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open Sans';
  pointer-events: none;
  cursor: default; }

.downloads .icon-catalogus {
  font-family: 'icomoon';
  color: #2b3e6b;
  font-size: 62px;
  opacity: 0.85;
  content: '\e0e0'; }

.downloads .Catalogus__block:hover .icon-catalogus {
  opacity: 1; }

.Footer {
  width: 100%;
  background: linear-gradient(rgba(60, 59, 62, 0.95), rgba(60, 59, 62, 0.95)), url("/front/images/asfalt.jpg");
  font-family: 'roboto', 'sans-serif';
  font-size: 18px;
  line-height: 1.9;
  letter-spacing: 0.1px;
  color: #fff;
  font-weight: 300;
  padding-top: 85px;
  padding-bottom: 85px; }
  @media (max-width: 991px) {
    .Footer {
      font-size: 15px;
      line-height: 2.0; } }
  @media (max-width: 767px) {
    .Footer {
      padding: 50px 0;
      float: left; } }

.LogoStack {
  padding-left: 0;
  width: 20%; }
  @media (max-width: 991px) {
    .LogoStack {
      width: 35%; } }
  @media (max-width: 767px) {
    .LogoStack {
      width: 50%;
      padding-right: 0; } }

.LogoStack__Logo {
  height: 45px;
  width: 152px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 30px; }
  @media (max-width: 991px) {
    .LogoStack__Logo {
      margin: 30px auto 0 auto; } }

.LogoStack__Logo--drive {
  background-image: url("/front/images/drive.png"); }
  @media (max-width: 640px) {
    .LogoStack__Logo--drive {
      width: 100px; } }

.LogoStack__Logo--repair {
  background-image: url("/front/images/repair.png"); }
  @media (max-width: 640px) {
    .LogoStack__Logo--repair {
      width: 100px; } }

.LogoStack__Logo--coach {
  background-image: url("/front/images/coach.png"); }
  @media (max-width: 640px) {
    .LogoStack__Logo--coach {
      width: 100px; } }

.Footer__Info {
  border-left: 1px solid #fff;
  width: 80%; }
  @media (max-width: 991px) {
    .Footer__Info {
      width: 65%; } }
  @media (max-width: 767px) {
    .Footer__Info {
      width: 50%;
      padding-left: 30px;
      font-size: 16px; } }

.Footer__Info__Mail {
  color: white; }

.Footer__Column__Header {
  font-weight: 400;
  margin-bottom: 40px; }

@media (max-width: 767px) {
  .Footer__Menu {
    margin-top: 40px; } }

.Footer__Column__Content__Menu {
  padding: 0;
  list-style: none; }

.Footer__Column_Content__Link {
  color: #fff; }
  .Footer__Column_Content__Link:hover {
    color: #fff; }

/*
* Header
*/
@media (min-width: 992px) {
  .HeaderFullWidth {
    min-height: 460px;
    max-height: 750px; } }

.HeaderFullWidth__button {
  background: #c3122f;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  border-radius: 100px;
  letter-spacing: 0;
  padding: 8px 49px;
  margin-top: 72px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3); }
  @media (min-width: 991px) and (max-width: 1440px) {
    .HeaderFullWidth__button {
      margin-top: 40px; } }
  @media (max-width: 767px) {
    .HeaderFullWidth__button {
      margin-top: 60px; } }
  .HeaderFullWidth__button:hover {
    color: #fff;
    background-color: #ac1029; }
  .HeaderFullWidth__button:focus {
    color: #fff;
    background-color: #ac1029; }

.HeaderFullWidth__button-text {
  margin: 0;
  color: white; }

.Transition--slide-inTransition--slide-in {
  display: none; }

.HeaderFullWidth__title--holder {
  display: none; }

.HeaderFullWidth__FullDiv--Overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); }

/*
* Floating images
*/
@media (max-width: 767px) {
  .Floating-images {
    padding: 0 27px; } }

.Title-images-column__row__image {
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 38px;
  text-transform: none;
  height: 240px;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  color: white; }
  @media (min-width: 768px) and (max-width: 991px) {
    .Title-images-column__row__image {
      height: 160px; } }

.Floating-images__row__content {
  border-radius: 4px;
  font-weight: 600;
  color: white;
  letter-spacing: 5.7px;
  font-size: 35px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }

@media (min-width: 992px) {
  .Floating-images__row__content__hover-overlay:hover {
    background-image: linear-gradient(237deg, #c41330, #5d4c9f); } }

@media (max-width: 768px) {
  .Homepage-about-us {
    padding: 0 27px; } }

.Homepage-about-us .Half-page-text--even:first-child {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 768px) {
  .Information-column-container__text-holder {
    padding: 0; } }

/*
* Title text button column
*/
.Information-column-container {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: left; }

.Information-column-container__header-text {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: #c2122e;
  text-transform: none;
  line-height: 33px; }
  @media (max-width: 767px) {
    .Information-column-container__header-text {
      margin-bottom: 12px; } }

.Information-column-container__text-holder {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: #1d1d1d;
  font-weight: 300;
  margin-bottom: 30px; }

@media (max-width: 767px) {
  .Information-column-container__button--alignment {
    text-align: center; } }

.Information-column-container__button {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background-color: #c3122f;
  text-transform: none;
  border-radius: 100px;
  margin-top: 0px; }

.Information-column-container__button:hover {
  background-color: #ac1029;
  color: #fff; }

.img-box {
  height: 300px;
  width: 100%;
  overflow: hidden; }

.wh {
  height: 100% !important; }

.ww {
  width: 100% !important; }

.home .Half-page-rows {
  display: flex;
  flex: 1;
  min-height: 100%;
  align-items: center; }
  @media (max-width: 991px) {
    .home .Half-page-rows {
      display: block; } }

.Half-page-image__container .home__image {
  background-size: cover;
  background-position: center;
  height: 300px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0px; }
  @media (max-width: 991px) {
    .Half-page-image__container .home__image {
      margin-top: 30px; } }

/*
* Title images column
*/
.Title-images-column {
  background-color: #ebf0f6;
  font-weight: 600; }

.Title-images-column__header-text {
  font-size: 30px;
  letter-spacing: 2px;
  color: black;
  font-weight: 600;
  margin-top: 0; }

@media (min-width: 992px) {
  .Title-images-column__row__content-and-hover:hover .Title-images-column--hoverstate {
    background: linear-gradient(237deg, #00b4ff 6%, #442896 100%);
    opacity: 0.3; } }

.Title-images-column--hoverstate {
  background-image: linear-gradient(237deg, #00b4ff 6%, #442896 100%);
  opacity: 0.6;
  width: 100.2%;
  height: 100.4%;
  border-radius: 4px; }

/*
* News block
*/
@media (max-width: 768px) {
  .News-block__container {
    padding-left: 27px;
    padding-right: 27px; } }

.News-block__header-text {
  font-size: 28px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  color: #c2122e;
  text-transform: none; }

.News-block__row__item__text {
  height: 215px;
  padding: 20px 20px 0 20px;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 26px;
  letter-spacing: 0.5px;
  color: #001221;
  background-color: #fff; }

.Masthead {
  height: 64px;
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  background: #fff;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 768px) {
    .Masthead {
      height: 50px; } }

.Masthead--scroll {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1); }

.logo-link {
  text-decoration: none; }

.HeaderFullWidth__FullDiv--Overlay {
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.3) 1%, transparent 30%); }

.Masthead .container {
  height: 100%;
  padding-right: 0;
  padding-left: 0; }

.Masthead__logo {
  z-index: 2;
  width: 60px;
  text-align: left; }
  @media (max-width: 768px) {
    .Masthead__logo {
      width: 110px;
      margin-left: 14px; } }

.Masthead__logo--icon {
  padding-left: 15px;
  padding-right: 15px; }

@media (max-width: 768px) {
  .Masthead__logo img {
    width: 110px;
    margin-left: 14px; } }

.NavBar__Logo__Image {
  width: 110px; }

.background-header-fading-color {
  height: 100%;
  width: 100%; }

.Masthead__menu {
  width: 100%; }

.Masthead__menu-items {
  float: left;
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 30px;
  letter-spacing: 1.1px; }

.Masthead__menu-items__item {
  display: inline;
  margin-right: 40px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #3c3b3e;
  font-weight: 300; }
  @media (max-width: 1199px) {
    .Masthead__menu-items__item {
      margin-right: 25px; } }
  .Masthead__menu-items__item:hover {
    color: #3c3b3e;
    opacity: 1;
    cursor: pointer; }
  .Masthead__menu-items__item:active {
    color: #3c3b3e;
    opacity: 1; }
  .Masthead__menu-items__item:focus {
    opacity: 1;
    color: #3c3b3e;
    text-decoration: none; }

.Masthead__menu-items__item__link:active {
  color: #3c3b3e;
  opacity: 1; }

.Masthead__menu-items__item__link:focus {
  text-decoration: none;
  opacity: 1; }

.Masthead__menu-items__item__link-active {
  color: #3c3b3e;
  transition: color 0.2s linear;
  opacity: 1; }

.Masthead__menu-items__item__link-focus {
  color: #3c3b3e;
  opacity: 1.0;
  transition: color 0.2s linear; }

.Masthead__menu-right {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  top: 0; }
  @media (min-width: 991px) {
    .Masthead__menu-right {
      width: 160px; } }
  @media (max-width: 767px) {
    .Masthead__menu-right {
      position: absolute;
      right: 0; } }

.Masthead__menu-right i {
  color: #fff; }

.Masthead__menu-right__phone-number {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.1px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin: 0px; }

@media (max-width: 991px) {
  .slideout--searchbg {
    background-color: #2b3e6b;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%; } }

.Dropdown__languages {
  position: relative;
  -webkit-align-self: center;
  align-self: center; }
  @media (max-width: 991px) {
    .Dropdown__languages {
      margin-bottom: -5px; } }
  @media (max-width: 767px) {
    .Dropdown__languages {
      margin-bottom: -10px; } }

.Masthead__menu-right__search__button,
.Masthead__menu-right__language-select-button {
  display: inline-block;
  cursor: pointer;
  position: relative;
  right: 5px; }

.Masthead__menu-right__search__button {
  margin: 6px 20px 0 25px;
  position: relative;
  float: left; }
  @media (max-width: 991px) {
    .Masthead__menu-right__search__button {
      margin: 0 20px 0 25px; } }

.Masthead__menu-right__search__input {
  position: absolute;
  right: 40px;
  top: -7px;
  padding: 5px 5px 5px 10px;
  background: none;
  color: #fff;
  border: 0;
  outline: 0;
  pointer-events: none;
  border-bottom: 1px solid #fff;
  font-style: italic;
  width: 250px; }
  @media (max-width: 991px) {
    .Masthead__menu-right__search__input {
      width: 90vw; } }
  @media (max-width: 580px) {
    .Masthead__menu-right__search__input {
      width: 85vw; } }
  @media (max-width: 480px) {
    .Masthead__menu-right__search__input {
      width: 80vw; } }

.Masthead__menu-right__search__input--active {
  pointer-events: inherit;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  @media (max-width: 991px) {
    .Masthead__menu-right__search__input--active {
      border-bottom: none; } }

.toggle-button:hover {
  cursor: pointer; }

.Masthead__menu-right__search__input--active:hover {
  border-bottom: 1px solid white; }
  @media (max-width: 991px) {
    .Masthead__menu-right__search__input--active:hover {
      border-bottom: none; } }

.Masthead__language__select__dropdown {
  width: 205px;
  top: 40px;
  right: 0px;
  padding: 0;
  text-align: right;
  position: absolute;
  left: inherit;
  float: none;
  margin: 0; }

.Masthead__language__select__dropdown > li > a {
  padding: 16px 20px; }

.Masthead__language--select__current {
  cursor: pointer;
  width: 21px; }

.Masthead__language__select__image {
  margin-top: -3px;
  padding-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 24px;
  margin-right: 9px;
  height: 16px;
  margin-left: 11px; }

.Masthead__language__select__link {
  font-size: 14px;
  padding: 16px 17px 16px 0px; }

.Masthead__language__select__dropdown > .Masthead__language__select > .Masthead__language__select__link:first-child:hover {
  border-radius: 4px; }

.Masthead__language__select__dropdown > .Masthead__language__select > .Masthead__language__select__link:last-child:hover {
  border-radius: 4px; }

@keyframes Navbar--items--fadeanimation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Masthead__Navbar--items--fadeanimation {
  opacity: 0;
  animation-name: Navbar--items--fadeanimation;
  animation-duration: 1s; }

/*
* Responsive
*/
.Masthead__menu-items {
  float: right; }

.Masthead__menu-items__item__link--demo-link {
  color: #00b4ff;
  font-weight: 500; }
  .Masthead__menu-items__item__link--demo-link:hover {
    color: #00A2E5; }
