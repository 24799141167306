.Button__dropdown{
	margin-bottom: 100px;
}

.Button__dropdown__menu{
	z-index: 0; 	
	margin: 20px;
}

.Button__dropdown__menu__item{
	text-align: left;
	font-size: 18px;
    font-family: "Roboto", sans-serif;
    color: #fff;
    background-color: #c3122f;
    text-transform: none;
    border-radius: 100px;
    margin-top: 0px;
    text-align: center;
    border: 0px;
    width: 175px;
    padding: 10px 25px;

    @media(max-width: 767px){
    	margin-top: 10px;
    	margin-left: 0px;
        width: 200px;
    }
}

.Button__dropdown__menu__item:hover{
	background-color: #ac1029;
    color: #fff;
}

.Button__dropdown__menu__item__text{
	pointer-events: auto;

    @media(min-width: 769px){
        padding: 0px 10px;
    }
}

.Button__dropdown__menu__item__icon{
	height: 20px;
	width: 20px;
	margin-right: 5px;
}

.Button__dropdown__menu > li > a{
	padding: 15px;
	width: 100%;
}

.Information-column-container__button{
	pointer-events: auto;
}
