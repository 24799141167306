$breakpoints-mobile-max : 991px;
$breakpoints-desktop-min : 992px;
$breakpoints-desktop-normal-max : 1199px;


.about-us{
	.HeaderFullWidth__title {
		margin-top: -30px;
	}

	.pswp__caption__center{
		text-align: center;
		font-weight: 600;
	}

	.Information-column-container__text-holder {
	  margin-bottom: 30px;
	}

	.Footer{
		margin-top: 70px;

		@media(max-width: 991px){
			margin-top: 35px;
		}
	}

	.Photo-swipe-gallery__image--hover{
		opacity: 0.90;
	}

	.Photo-swipe-gallery__image--hover:hover{
		opacity: 1;
	}

	.Half-page-rows:nth-child(even) .Information-column-container__padding{
		margin-left: 8.33333333%;

		@media(min-width: 992px) and (max-width: 1440px){
			margin-left: 0px;
		}

		@media(max-width: 991px){
			margin-left: 0px;
		}
	}

	.Half-page-rows:nth-child(odd) .Information-column-container__padding{
		@media(min-width: 992px){
		padding-right: 50px;
		}
	}
	

	//* Textblocks about us page

	.Information-column-container__padding{
		@media(max-width: 991px){
			padding: 0px;
		}

		@media(max-width: 767px){
			padding: 15px;
		}
	}

	.Textblocks__container{
	margin-top: 20px;

		@media(max-width: 767px){
			margin-top: 50px;
		}
	}
	
	.Half-page-rows{
		margin-top: 80px;
		display: flex;

		@media(max-width: 991px){
			margin-top: 30px;
		}
	}

	.Half-page-rows:nth-child(odd){
		@media(max-width: 991px){
			flex-direction: column;
		}
	}

	.Half-page-rows:nth-child(odd) .Half-page-image__container{
		@media(max-width: 991px){
			padding-top: 30px;
		}
	}

	.Half-page-rows:nth-child(even) .Half-page-text--even{
		@media(max-width: 991px){
			padding-left: 0px;
		}
	}
	

	.Half-page-rows:nth-child(even) .Half-page-image__container{
		@media(max-width: 991px){
			padding-left: 0px;
			padding-top: 30px;
		}

		@media(max-width: 767px){
			padding-left: 15px;
		}
	}

	.Half-page-rows:nth-child(even){
		display: flex;
		flex-direction: row-reverse;
		position: relative;
		left: -80px;
			
		@media(min-width: 992px) and (max-width: 1440px){
			left: 0px;
		}	
		  @media(max-width: 991px){
		    flex-direction: column;
		    margin-left: 0px;
		    padding-left: 0px;
		    left: 0px;
		  }
	}

	.Half-page-image__container{
		display: flex;
		flex: 1;
		min-height: 100%;
		align-items: center;

		@media(max-width: 991px){
			display: block;
			flex: none;

		}

		@media(max-width: 767px){
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}