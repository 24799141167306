.MediaTextBlock{
  min-height: 500px;
  margin:100px auto;

  @media(max-width:991px){
    width: 100%;
    margin:100px auto 0 0;
    padding-right:25px;
  }

  @media(max-width: 767px){
    padding:0 5px;
    margin:0 auto 0 0;
    float:left;
  }
}

.MediaTextBlock__row{
  display:block;
  height:100%;
  margin-bottom: 80px;

  @media(max-width:991px){
    margin:0;
  }
}

.MediaTextBlock__MediaContrainer, .MediaTextBlock__TextContrainer{
  display:block;
  height:100%;

  @media(max-width: 991px){
    padding-right:0;
  }

  @media(max-width: 767px){
    padding-left:0;
  }
}

.MediaTextBlock__MediaContainer__MediaBlock{
  display:block;
  width:calc(100% + 180px);
  margin-left:-55px;
  height: 500px;
  max-height:100%;
  background-position:center;
  background-size:cover;
  z-index:1;

  @media(max-width:991px){
    width: calc(100% + 118px);
  }

  @media(max-width: 767px){
    width:100%;
    margin:0;
    padding-left:0;
  }
}

.MediaTextBlock__TextContainer__TextBlock{
  width:calc(100% - 90px);
  height: 366px;
  background:linear-gradient(
                  rgba(60,59,62, 0.95),
                  rgba(60,59,62, 0.95)
  ), url('/front/images/asfalt.jpg');
  border-bottom:10px solid #efa72d;
  padding:35px 45px 35px 45px;

  color:#fff;
  z-index:2;
  position:relative;
  right:-30px;


  @media(max-width:991px){
    width:100%;
    //height: 400px;
    right:0;
    height: auto;
  }

  @media(max-width: 1200px){
    height: auto;
  }

  @media(max-width:767px){
    height:auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.MediaTextBlock__TextContainer__TextBlock__Title{
  font-size: 24px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif';
}

.MediaTextBlock__TextContainer__TextBlock__Title__Light{
  font-weight:300;
}

.MediaTextBlock__TextContainer__TextBlock__Title__Regular{
  font-weight:400;
  margin-bottom:45px;

  @media(max-width:991px){
    margin-bottom:25px;
  }
}


.MediaTextBlock__TextContainer__TextBlock__Text{
  font-family: 'Roboto', 'sans-serif';
  font-weight:300;
  font-size: 18px;
  line-height: 1.7;

  @media(max-width:991px){
    font-size: 15px;
    line-height: 2.0;
  }
}

.MediaTextBlock__TextContainer__TextBlock__Text__Dot{
  color:#00b4ff;
}

.MediaTextBlock__TextContainer__LinkContainer{
  width:calc(100% - 60px);
  text-align:right;
  margin-top:70px;

  @media(max-width:767px){
    width:100%;
    text-align:center;
    margin-top:65px;
    margin-bottom:66px;
  }
}

.MediaTextBlock__TextContainer__LinkContainer_Link{
  font-family: 'Roboto', 'sans-serif';
  font-weight:500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase;

  &:hover{
    color:#00A2E5;
  }

  @media(max-width: 767px){
    border:1px solid #00b4ff;
    padding:16px 23px;
  }
}

.MediaTextBlock__TextContainer__LinkContainer_Link__Icon{
  font-size: 12px;
  margin-right: 30px;
}

//AlignRight
.MediaTextBlock--AlignRight{
  padding-left:25px;

  @media(max-width:991px){
    padding-right:0;
    margin: 100px 0 0 auto;
  }

  @media(max-width:767px){
    padding-right:5px;
    margin:0 0 0 auto;
    padding-left:5px;
  }

  .MediaTextBlock__MediaContainer__MediaBlock {
    margin-left: -130px;

    @media(max-width: 991px){
      margin-left: -115px;
    }

    @media(max-width: 767px){
      width:100%;
      margin:0;
    }
  }

  .MediaTextBlock__TextContainer__TextBlock{
    @media(max-width:991px){
      width:calc(100% - 20px);
    }

    @media(max-width:767px){
      width:100%;
    }
  }

  .MediaTextBlock__TextContrainer{
    @media(max-width:991px){
      padding-left:0;
    }
  }

  .MediaTextBlock__TextContainer__LinkContainer{
    text-align:left;
    padding-left:30px;

    @media(max-width:767px){
      text-align:center;
      padding-left:0;
    }
  }
}

//intro
.MediaTextBlock--intro{
  margin-top:0;

  @media(max-width:767px){
    margin-bottom:70px;
  }


  .MediaTextBlock__TextContainer__TextBlock{
    border-bottom:none;
  }

  .MediaTextBlock__MediaContainer__MediaBlock{
    width:100%;
    margin:0;
    text-align:center;
  }

  .MediaTextBlock--intro__Image{
    margin:66px 0 0 0;

    width: 271px;
  }
}

//drive
.MediaTextBlock--drive{
  .MediaTextBlock__TextContainer__TextBlock__Text__Dot{
    color:#efa72d;
  }
}

//coach
.MediaTextBlock--coach{
  .MediaTextBlock__TextContainer__TextBlock{
    border-color:#2c74ad;
    background:#ebebeb;
    color:#3c3b3e;
  }

  .MediaTextBlock__TextContainer__TextBlock__Text__Dot{
    color:#2c74ad;
  }
}

//repair
.MediaTextBlock--repair{
  .MediaTextBlock__TextContainer__TextBlock{
    border-color:#c62531;
  }

  .MediaTextBlock__TextContainer__TextBlock__Text__Dot{
    color:#c62531;
  }
}

//outro
.MediaTextBlock__BackGround{
  background-color: rgba(235, 235, 236, 0.5);
  width:100%;
  padding:0 0 50px 0;
}

.MediaTextBlock--outro{
  margin:0 auto;

  .MediaTextBlock__TextContainer__TextBlock {
    border-bottom: none;
  }

  .MediaTextBlock__TextContainer__LinkContainer_Link{
    background-color:#00b4ff;
    color:#fff;
    padding:16px 23px;
    margin-left:-30px;

    &:hover{
      background-color:#00A2E5;
    }

    @media(max-width: 767px){
      margin:0;
    }
  }
}
