#myCarousel {
  height: 600px;

  @media (max-width: 768px){
  	display: none;
  }
}

#myCarousel .item{
  height: 600px;
}

.carousel-inner__item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.myCarousel__inner__item__column {
  margin: 0 auto;
  text-align: center;
}