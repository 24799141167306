
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?jzd1rw');
    src:    url('../fonts/icomoon.eot?jzd1rw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?jzd1rw') format('woff2'),
    url('../fonts/icomoon.ttf?jzd1rw') format('truetype'),
    url('../fonts/icomoon.woff?jzd1rw') format('woff'),
    url('../fonts/icomoon.svg?jzd1rw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-scrollIndicator:before {
    content: "\e900";
}
.icon-arrowRight:before {
    content: "\e901";
}
.icon-quote:before {
    content: "\e902";
}


