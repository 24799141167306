.Pic-text {
  height: 100%;
  position: relative;

  @media(max-width: 991px) {
  }

  .Pic-text__holder {
    display: flex;
    flex-direction: row;

    @media(max-width: 667px) {
      flex-direction: column-reverse;

      .Utils__vertical-align {
        height: 100%;
      }
    }
  }

  .Pic-text__image {
    min-height: 160px;
    width: 100%;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 991px) {
      min-height: 220px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 667px) {
      min-height: 300px;
      margin-top: 25px;
    }

    @media (max-width: 320px) {
      min-height: 235px;
    }
  }

  .col-md-4 {
    padding: 0;
  }

  .col-md-8 {
    padding-left: 30px;
    padding-right: 0px;
    text-align: left;

    @media(max-width: 992px) {
      padding-left: 15px;
    }

    @media(max-width: 667px) {
      padding-left: 0px;
    }
  }

  .Pic-text__title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    width: 90%;
  }

  .Pic-text__text {
    //height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.9);
    max-height: 100px;
    overflow: hidden;
  }

  .Pic-text__text .Utils__vertical-align {
    text-align: left;
  }

  .Pic-text__more {
    height: auto;
    font-size: 14px;
    color: #000000;
    font-style: italic;
    opacity: 0.6;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .Pic-text__button:hover {
    .Pic-text__more, .icon-arrow-right:before {
      opacity: 1;
    }
  }

  .Pic-text__ID--block {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2b3e6b;
    width: auto;
    padding: 5px 10px;
    height: 30px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;

    @media(max-width: 992px) {
      margin-top: 6px;
      position: relative;
      margin-bottom: 10px;
    }
  }

  .icon-arrow-right:before {
    top: 2px;
    content: "\e315";
    font-family: 'icomoon';
    color: #000;
    position: relative;
    font-size: 18px;
    opacity: 0.6;
  }

}

.Product__seperator {
  margin-top: 30px;
  margin-bottom: 30px;
}

.Pic-text--last-child {
  margin-bottom: 100px;
}
