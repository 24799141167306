/* Gallery */

.image-gallery-information{
  text-align: left;
}
  /* Small Images */
    .small-images-gallery{
      margin-top: -50px;
    }


@media (max-width: 360px){
  .gallerypages{
    max-width: 300px;
  }
}

    /* Button Next to Small Images */
    .gallery-button{
      text-align: left;
      margin-top: -50px;
    }

    /* Little Responsive edit on the Button */
@media (max-width: 768px) {
  .gallery-button {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
}

/* Bootstrap table */
.gallery-text{
  font-size: 16px;
}
.table-gallery{
  font-size: 10px;
  color: white;
  background-color: grey;
}

//floating pictures

.floating-pictures{
  margin-top:-90px;
  position:relative;
  z-index:1;
}
