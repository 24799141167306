/* Homepage */
/* Sticky Footer Scooped*/
body{
  background-color:#ffffff;
}

.masthead-brand {
  margin-top: 4px;
}

a:hover{
  text-decoration: none;
}

.page-header-container {
  margin-top: 25px;
  max-width: 800px;
}

.Utils--Center--Align {
  text-align: center;
}

.page-header-content {
  margin-top: 20%;
  text-align: center;
}

.page-content {
  text-align: center;
}

.more-about-us-div {
  text-align: center;
  margin-top: 150px;
}

.more-about-us {
  width: auto;
  margin-top: 150px;
  font-size: 22px;
  color: #0000ed;
  border-bottom-color: #0000ed;
  border-bottom: 2px solid;
}

//a:hover a:focus {
//  color: none !important;
//}
//
//a{
//  color: #C3122F;
//
//  &:hover{
//    color: #C3122F;
//    text-decoration: underline;
//  }
//}

/* IcoMOON icons */

.icon-share:before {
    content: "\e900";
}

.icon-catalogus:before{
  content: "\e0e0";
}

.icon-share{
  color: white;
  margin-right: 25px;
  font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

/* Marketing Featurettes */
.gallerypages {
  margin-top: 150px;
  margin-bottom: 100px;
}

.gallerypagescontent {
  color: #333;
  border: 2px solid #333;
  padding-left: 0;
  padding-right: 0;
}

/*gallery */
.image-with-bar {
  margin-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto;
}

.image-info {
  padding: 15px 20px;
  background: #333;
  font-family: DecimaNova, Arial, sans-serif;
  color: grey;
  max-width: 360px;
}

.hidden--search{
  @media(max-width:991px)
  {
    display:none;
  }
}

.show--search{
  @media(max-width:991px) {
    display: block;
  }
}

@media (max-width: 360px) {
  .cover-heading {
    margin-top: 70%;
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .cover-heading {
    margin-top: 50%;
  }
  .image-with-bar {
    text-align: center;
  }
  .image-info {
    margin-left: auto;
    margin-right: auto;
  }
}

.image-info h3 {
  margin-top: 0;
}

/* Carousel slider */
#myCarousel {
  margin-top: 50px;
}

.carousel-text-container {
  width: 70%;
}

.Footer__container__item {
  text-align: left !important;
  color: #333;
}

.Footer__container__menu__link {
  color: #333;
}

.Footer__container__menu__register{
  margin-bottom: 20px;
  background-color: #222222;
  padding: 20px;
  font-size: 20px;
}

/* Content page */

.banner-content-button {
  text-align: center !important;
}

.bannerjumbotron {
  top: 100px;
  position: relative;
  background-color: transparent;
  background-image: url('/images/hvdk.png');
  background-repeat: no-repeat;
  background: cover;
  height: 600px;
  width: 100%;
  margin: 0 auto;
  resize: both;
  margin-bottom: 200px;
}

.jumbotroncolor {
  padding: 20px;
  resize: both;
  margin: 0 auto;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  height: 600px;
}

.bannerjumbotron h2, .bannerjumbotron p {
  text-align: center;
  color: #fff;
  width: 100%;

}

.bannerjumbotron p {
  position: relative;
  margin-bottom: 50px;
}

.bannerjumbotron h2 {
  margin-top: 7.5%;
}

@media (max-width: 768px) {
  .bannerjumbotron {
    height: 300px;
  }
  .jumbotroncolor {
    height: 400px;
  }
}

@media (max-width: 1024px) {
  .bannerjumbotron {
    height: 400px;
  }
  .jumbotroncolor {
    height: 400px;
  }
}

.banner-content-button {
  margin: 0;
  background: white;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.contenth2 {
  right: 5% !important;
  bottom: 5% !important;
}


/* Fullpage image */

.fullpagecover {
  height: 100vh;
  resize: both;
  margin: 0 auto;
  position: relative;
}

.fullpageimage-image {
  height: 100vh;
}

@media (max-width: 1024px) {
  .fullpagecover {
    height: 75vh;
  }
  .fullpageimage-image {
    height: 75vh;
  }
}

@media (max-width: 768px) {
  .fullpagecover {
    height: 50vh;
  }
  .fullpageimage-image {
    height: 50vh;
  }
}

@media (max-width: 480px) {
  .fullpagecover {
    height: 30vh;
  }
  .fullpageimage-image {
    height: 30vh;
  }
}

.arrow-container{
  min-height:20px;
  text-align:center;
}

@keyframes moving {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
    color:transparent;
  }
}

@-webkit-keyframes moving {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
    color:transparent;
  }
}

.moving
{
  -webkit-animation: moving 2s infinite;
  animation:moving 2s infinite;
}

.pictures-column-container{
  position:relative;
  width:100%;
  height:450px;
}

.pictures-column-container .container{
  height:100%;
}

.gallery-row{
  padding-bottom:30px;
}

.carousel-buttons{
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-right{
  right:100px;
}

.carousel-left{
  left:100px;
}