@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;

    @media(max-width:767px){
      padding:0;
    }
  }
  > .VideoHeader__VideoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media(max-width:767px){
      width:100%;
      position:relative;
    }
  }
}

.VideoHeader{

  //width:100%;
  //height: 529px;

  width:100%;
  background-color: rgba(235, 235, 236, 0.5);


}

.VideoHeader__container{
  //display:block;
  //height:100%;
  //position:relative;
  @include aspect-ratio(16, 9);

  @media(max-width: 991px){
    padding-left: 0;
    margin: 0;
    width:100%;
    padding-right:0;
  }

  @media(max-width: 767px){
    float:left;
    position:relative;
    float:left;
    width:100%;
  }
}

.VideoHeader__VideoContainer{
  display:block;
  width:100%;
  height:100%;
  margin-left:-55px;

  @media(max-width:991px){
    margin-left:0;
    width:calc(100% - 105px);
  }

  @media(max-width: 767px){
    margin:0;
  }
}

.VideoHeader__VideoContainer__Video{
  width:100%;
  height:100%;
}

.VideoHeader__CalToAction{
  width: 210px;
  height: 210px;
  right:-35px;
  background-color: #00b4ff;
  position: absolute;
  top:0;
  padding:20px;
  text-decoration: none;
  color:#fff;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.8px;

  &:hover{
    color:#fff;
    background:#00A2E5;
  }

  @media(max-width:991px){
    right:0;
  }

  @media(max-width:767px){
    width:calc(100% - 10px);
    float:left;
    height:auto;
    position:relative;
    margin:0 0 0 5px;
    margin-bottom:45px;
  }
}

.VideoHeader__CalToAction__Text{
  font-size: 24px;
}

.VideoHeader__CalToAction__SubText{
  margin-top:50px;
  width:100%;
  text-align:right;
}

.VideoHeader__CalToAction__SubText__Icon{
  float:left;
}