$breakpoints-mobile-max: 991px;
$breakpoints-desktop-min: 992px;
$breakpoints-desktop-normal-max: 1199px;

.js-Photo-swipe-gallery {
  //margin-top: -50px;
}

.Photo-swipe-gallery__image__a__img--featured {
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 4px;
  max-height: 516px;
}

.Photo-swipe-gallery__image__a__img--small {
  max-width: 100%;
  line-height: 50px;
  margin: auto;
  display: block;
}

.Photo-swipe-gallery__image--small {
  border-radius: 4px;
  height: 65px;
  background-position: 100px;
  width: 13.187513228%;
  display: inline-block;
  margin-bottom: 8px;
  vertical-align: middle;
  text-align: center;
  margin-right: 0.896780557%;
  overflow: hidden;

  @media(max-width: $breakpoints-mobile-max) {
    width: 100%;
    float: none;
    display: none;
  }

  @media(max-width: $breakpoints-desktop-normal-max) {
    width: 13.1190647%;
  }
}

.Photo-swipe-gallery__image--small:nth-child(8), .Photo-swipe-gallery__image--small:nth-child(16),
.Photo-swipe-gallery__image--small:nth-child(24), .Photo-swipe-gallery__image--small:nth-child(32),
.Photo-swipe-gallery__image--small:nth-child(40), .Photo-swipe-gallery__image--small:nth-child(48) {
  margin-right: 0px;
}