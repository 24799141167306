.QuoteSlider{
  min-height: 365px;
  position:relative;
  padding:0;
  margin-bottom:100px;

  @media(max-width:767px){
    float:left;
  }
}

.QuoteSlider__Row{
  display: block;
  min-height:365px;
  position:absolute;

  @media(max-width:991px){
    padding:0 30px 0 30px;
  }

  @media(max-width:767px){
    width:100%;
    float:left !important;
    padding: 0 22px 0 52px;
  }
}

.QuoteSlider__Row__Column{
  display: block;
  height:100%;
  min-height:365px;
  padding:0;

  @media(max-width:991px){
    min-height:0;
  }
}

.QuoteSlider__ImageBlock{
  display:block;
  height:100%;
  width:100%;
  margin-left:-55px;
  position:relative;
  min-height:365px;

  @media(max-width: 991px){
    width:100%;
    margin:0 0 65px 0;
  }

  @media(max-width: 767px){
    min-height:200px;
    width:100%;
    margin:0 0 65px 0;
  }
}

.QuoteSlider__ImageBlock__Image{
  position:absolute;
  width:100%;
  height:100%;
  background-size:cover;
  background-position:center;
  z-index:1;
}

.QuoteSlider__ImageBlock__Avatar{
  width:80px;
  height:80px;
  position:absolute;
  right:0;
  margin:40px -40px 0 0;
  border-radius:50%;
  z-index:2;

  @media(max-width:992px){
    right:50%;
    bottom:-40px;
  }
}

.QuoteSlider__ImageBlock__Avatar__Image{
  width:100%;
  dispay:block;
  height:100%;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  border-radius:50%;
}

.QuoteSlider__TextBlock{
  font-family:'Roboto', 'sans-serif';
  font-weight:300;
}

.QuoteSlider__TextBlock__Title{
  font-size: 28px;
  letter-spacing: 0.2px;
  color: #3c3b3e;
  margin:-9px 0 37px 0;
}

.QuoteSlider__TextBlock__Title__Job{
  font-weight:400;
}

.QuoteSlider__TextBlock__Content{
  font-style: italic;
  font-size: 18px;
  line-height: 1.7;
  color: #000000;
  position:relative;
  margin-left:30px;
  padding-right:100px;

  @media(max-width:767px){
    padding-right:0;
  }
}

.QuoteSlider__TextBlock__Content__QuoteSign{
  font-size:50px;
  margin:-30px 0 0 -30px;
  color:rgba(60, 59, 62, 0.1);
  position:absolute;
}

.QuoteSlider__TextBlock__LinkContainer{
  margin-top:80px;

  @media(max-width:991px){
    margin-top:37px;
  }

  @media(max-width: 767px){
    text-align:center;
    margin:59px 0 65px 0;
  }
}

.QuoteSlider__TextBlock__LinkContainer__Link{
  font-family: 'Roboto', 'sans-serif';
  font-weight:500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase;

  &:hover{
    color:#00A2E5;
  }

  @media(max-width:767px){
    border:1px solid #00b4ff;
    padding: 16px 23px;
  }
}

.QuoteSlider__TextBlock__LinkContainer__Link__Icon{
  font-size: 12px;
  margin-right: 30px;
}