.News-block {
  margin-bottom: 70px;

  @media(max-width: 767px){
    margin-bottom: 50px;
  }
}

.News-block__header-text {
  margin-bottom: 40px;
  margin-top: 100px;
  text-transform: uppercase;

  @media(max-width: 767px){
    margin-top: 75px;
    margin-bottom: 30px;
  }
}

.News-block__row {
  padding-bottom: 30px;
}

.News-block__row__item {
  width: 100%;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  @media(max-width:992px)
  {
    margin-bottom:15px;
  }
}

.News-block__row__item:hover {
  cursor: pointer;
}

.News-block__row__item__image {
  height: 180px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.News-block__row__item__text__title{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3; /* number of lines to show */
   line-height: 36px;        /* fallback */
   max-height: 110px;       /* fallback */
}

.News-block__row__item__text {
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 20px;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*
* Responsive
*/
@media(max-width: 768px) {
  .News-block__row__item__text {
    padding-left: 10px;
    padding-top: 5px;
    height: 60px;
  }

  .News-block__row {
    padding-bottom: 0;
  }

  .News-block__row__item__image {
    height: 160px;
  }
}