.Floating-images {
  min-height: 340px;
  margin-top: -90px;
  margin-bottom: 0px;

  @media(max-width: 991px){
    min-height: 260px;
    margin-top: -30px;
  }

  @media(max-width: 767px){
    margin-bottom: 50px;
  }
}


.Floating-images__row{
  margin-bottom: 30px;

  @media(max-width: 991px){
    margin-bottom: 0px;
  }
}

.Services .Floating-image__row{
  @media(max-width: 991px){
    margin-top: 30px;
  }
}

.Floating-images--responsive{
  @media(max-width: 992px){
    margin-bottom: 20px;
  }
}

.Floating-images__row__content {
  height: 320px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Floating-images__row__content--title {
  text-transform: uppercase;
}

.Floating-images__row__content__hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color:rgba(0, 0, 0, 0.1);
}

.Floating-images__row__content__subtitle {
  position: absolute;
  bottom: 30px;
  text-transform: none;
  left: 0;
  text-align: center;
  width: 100%;
}

.Floating-images__row__content:hover {
  cursor: pointer;
}

/*
* Responsive
*/
@media(max-width: 768px) {
  .Floating-images__row__content {
    height: 260px;
  }
}

@media(max-width: 360px) {
  .Floating-images__row__content {
    height: 160px;
  }
}

