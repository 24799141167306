.downloads .Masthead{
	background-color	: #2b3e6b;
}

.downloads{ 

.Catalogus__block__row--title{
	font-size		: 30px;
	font-weight		: 600;
	font-family		: 'Open Sans';
}

.Catalogus__block--text{
	font-size		: 20px;
	font-weight		: 600;
	font-family		: 'Open Sans';
	pointer-events	: none;
	cursor			: default;
}

.icon-catalogus {
	font-family	: 'icomoon';
	color 		: #2b3e6b;
	font-size	: 62px;
	opacity 	: 0.85;
    content 	: '\e0e0';
}

	.Catalogus__block:hover{
		.icon-catalogus{
			opacity 	: 1;
		}	
	}
}