.Title-images-column {
  text-transform: uppercase;
}

.Title-images-column__content {
  margin-top: 100px;
  margin-bottom: 100px;
}

.Title-images-column__header-text {
  margin-bottom: 40px;
}

.Title-images-column__row__image {
  position: relative;
  height: 184px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  text-transform: uppercase;
  
  @media(max-width: 767px){
    margin-bottom: 15px;
  }
}

.Title-images-column .col-md-3{
  border-radius:4px;
}

.Title-images-column--hoverstate:hover {
  cursor: pointer;
  border-radius:4px;
}

/*
* Responsive
*/
@media(max-width: 992px) {
  .Title-images-column {
    height: 100%;
  }
}

@media(max-width: 768px) {
  .Title-images-column__row__image {
    height: 160px;
  }
}