.Contact__form {
  min-height: 630px;
  height: auto;
  margin-bottom: 100px;
  margin-top: -90px;

  @media(max-width: 992px) {
    height: auto;
  }
  @media(max-width: 320px) {
    height: auto;
  }
}

.Contact--min--height {
  min-height: 670px;
  height: auto;
}

.Contact__form--shadow {
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.Contact__form__input-fields {
  height: 45px;
}

.Contact__form__input-fields, .Contact__form__content__left__form--message--textarea {
  font-size: 20px;
  color: #000;
  font-weight: 300;
}

.Contact__form__content__right {
  @media(max-width: 768px) {
    left: -15px;
  }
}

.Contact__form__content__right--title, .Contact__form__content__left--title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 52px;
  color: #000;

  @media(max-width: 1200px) {
    font-size: 26px;
  }

  @media(max-width: 992px) {
    font-size: 24px;
  }

  @media(max-width: 768px) {
    font-size: 22px;
  }

  @media(max-width: 320px) {
    font-size: 19px;
  }
}

.Contact__form__content__left__form--message--textarea {
  min-height: 135px !important;
}

.Contact__form__content__left__form--submit--button {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background-color: #ffa726;
}

.Contact__form__content__left__form--submit--button:hover {
  background-color: #ff9800;
  color: #fff;
}

.Contact__form__content__right--font-light {
  @include
  basic-text-styling();
  font-family: 'Roboto';
  font-weight: 300;
  margin-top: -5px;
}

.Contact__form__content__right--contact-information {
  font-weight: 400;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 2.3;
  font-family: 'Open Sans';
  @media(max-width: 768px) {
    font-size: 19px;
  }
  @media(max-width: 320px) {
    font-size: 16px;
  }
}

.Contact__form__content__right--contact-information:hover {
  color: rgba(0, 0, 0, 0.9);
}

/*
* Bootstrap classes override
*/

.Contact__form__content__left, .Contact__form__content__right, .Contact__form {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
}

.Contact__form__content__left--title {
  margin-left: -15px;
}