.contact {

  //.Masthead {
  // position : relative;
  // background-color : #c3122f;
  // margin-bottom: 100px;
  //
  // @media(max-width: 991px){
  //   margin-bottom: 50px;
  // }
  //}

  .Masthead {
    top: 0;
  }
}

.contact .MediaTextBlock--contact{
  margin-bottom:0;
}
