.TextColumn{
  width:100%;
}

.TextColumn__Text{
  font-family: 'Roboto', 'sans-serif';
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: #3c3b3e;
  font-weight:300;
  margin:50px 0 30px 0;
}