.CustomInfo {
  width: 100%;
  min-height: 530px;
  background-color: rgba(235, 235, 236, 0.5);
  margin-bottom: 80px;

  @media(max-width: 991px) {
    //margin-top:100px;
  }

  @media(max-width: 767px) {
    height: auto;
    margin-bottom: 35px;
    float: left;
  }
}

.CustomInfo__Container {
  display: block;
  height: 530px;
  max-height: 100%;

  @media(max-width: 991px) {
    padding: 0 30px;
  }

  @media(max-width: 767px) {
    height: auto;
  }
}

.CustomInfo__Row {
  display: block;
  height: 530px;
  max-height: 100%;
  padding: 80px 0;

  @media(max-width: 767px) {
    //padding:45px 36px;
    height: auto;
    padding-top: 0px;
  }
}

.CustomInfo__Column {
  display: block;
  height: 100%;
  padding: 0;
}

.CustomInfo__TextBlock {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300;
}

.CustomInfo__ImageContainer__TextBlock__Title {
  font-size: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 30px;

  @media(max-width: 991px) {
    font-size: 24px;
  }
}

.CustomInfo__ImageContainer__TextBlock__Title__Regular {
  font-weight: 400;
}

.CustomInfo__ImageContainer__TextBlock__Text {
  width: calc(100% - 90px);
  font-size: 18px;
  line-height: 1.7;

  @media(max-width: 991px) {
    font-size: 15px;
    line-height: 2.0;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
}

.CustomInfo__ImageContainer__Image {
  width: 100%;
  height: 100%;
  display: block;
  background-image: url('/front/images/afbeeldingCustomizen.jpg');
  background-size: cover;
  background-position: center center;

  @media(max-width: 767px) {
    height: 250px;
    margin-bottom: 35px;
  }
}

.CustomInfo__TextBlock__LinkContainer {
  width: calc(100% - 90px);
  margin-top: 50px;

  @media(max-width: 767px) {
    width: 100%;
    text-align: center;
  }
}

.CustomInfo__TextBlock__LinkContainer__Link {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #00b4ff;
  text-transform: uppercase;

  &:hover {
    color: #00A2E5;
  }

  @media(max-width: 767px) {
    border: 1px solid #00b4ff;
    padding: 16px 23px;
  }
}

.CustomInfo__TextBlock__LinkContainer__Link__Icon {
  font-size: 12px;
  margin-right: 30px;
}