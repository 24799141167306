body {
    font-family: 'Open Sans', sans-serif;
    padding-top:64px;
}

html {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

.last_child{
	margin-bottom: 100px!important;
}

.icon-close--navigation:before {
    content: "\e14c";
    color: #fff;
    font-family: 'icomoon';
    font-size: 20px;
    position: relative;
    top: -2px;

    @media(max-width: 991px){
    top: -5px;
    }
}

.icon-search--navigation:before {
    content: "\e8b6";
    color: #fff;
    font-family: 'icomoon';
    font-size: 20px;
    position: relative;
    top: -2px;
}