.HeaderFullWidth {
  width: 100%;
  height: 81vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*
* Responsive
*/
@media (max-width: 1199px) {
  .HeaderFullWidth {
    height: 506px;
  }
}



@media(max-width: 767px){
  .HeaderFullWidth {
    height: 540px;
  }
}

@media(max-width: 320px){
  .HeaderFullWidth{
    height: 375px;
  }
}
