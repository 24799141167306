.NewsLetter{
  width:100%;
  min-height: 138px;
  background-color: rgba(60, 59, 62, 0.1);
  padding-top:45px;

  @media(max-width:991px){
    margin-top:100px;
  }

  @media(max-width:767px){
    margin-top:0;
    height:auto;
    padding:57px 25px 68px 25px;
    float:left
  }
}

.NewsLetter__Text{
  font-family: 'Roboto', 'sans-serif';
  font-weight:300;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #3c3b3e;

  @media(max-width:991px){
    font-size: 15px;
    padding:9px 0 0 20px;
  }

  @media(max-width:767px){
    margin-bottom:45px;
    padding:0;
  }
}

.NewsLetter__Form{
  float:right;

  @media(max-width: 991px) {
    float:none;
  }
}

input.NewsLetter__Form__Input{
  width: 345px;
  height: 60px;
  background-color: #ffffff;
  border:none;
  outline:none;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  color: #3c3b3e;
  padding:20px;
  margin:0 -3px 0 0;

  @media(max-width: 991px){
    font-size:15px;
    width: calc(100% - 61px);
  }
}

.NewsLetter__Form__Button{
  width: 60px;
  height: 60px;
  background-color: #00b4ff;
  border:none;
  outline:none;
  color:#fff;
  position:relative;
  top:-1px;

  &:hover{
    background-color:#00A2E5;
  }
}