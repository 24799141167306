.News__overview{
	margin-bottom: 200px;
}

.News__overview__block__header{
	margin-top: -90px;
	border-radius: 4px;
	background-size: cover;
	max-height: 320px;
	width: 100%;
	z-index: 2;

}

.News__overview__block__header--image{
	border-radius: 4px;
	max-height: 320px;
	box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
	width: 100%;
	object-fit: cover;
	z-index: 1;
}

.News__overview__block__text{
	margin-top: 10px;
}

.News__overview__block__text--header{
	color: #000;
	font-size: 30px;
	font-family: 'Open Sans';
	letter-spacing: 2px;
	font-weight: 600;
	line-height: 41px; 
	margin-bottom: 30px;
}

.News__overview__block__text--text-block{
	font-size: 20px;
	font-family: 'Open Sans';
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.9);
}

.News__overview__block__button--button{
	margin-top: 20px;
}

