/* Contact page */
.contact-header-container {
  max-width: 800px;
  color: #333;
}

.contact-container{
  color: #333;
}

.contact-text-alignment{
  text-align: center;
}

.header {
  color: #333;
  font-size: 27px;
  padding: 10px;
}

.bigicon {
  font-size: 35px;
  color: #333;
}

    .background-contact-form{
      min-height: 20px;
      padding: 20px;
      margin-bottom: 20px;
      background-color: grey;
      color: #fff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    }


.Wysiwyg-text-image__text p{
  margin: 0 0 5px;
  font-family: 'Roboto';
}