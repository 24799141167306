.Pagination{
	margin-top: -100px;
}

.Pagination__pager{
	color: #2b3e6b;
}

.Pagination__pager--older{
	float: left;
}

.Pagination__pager--newer{
	float: right;
}

.Pagination__pager--text{
	position: relative;
	top: -5.5px;
}

.Pagination__pager .Pagination__pager--older .Pagination__pager--link,
.Pagination__pager .Pagination__pager--newer .Pagination__pager--link{
		border: none;
		padding: 5px 10px 5px 25px;
		border-radius: 4px;
	}


.Pagination__pager .Pagination__pager--older .Pagination__pager--link{
		padding: 5px 25px 5px 10px;
	}

	.Pagination__pager--text-left{
		margin-left: 10px;
	}

	.Pagination__pager--text-right{
		margin-right: 10px;
	}