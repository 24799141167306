/*
 * Globals
 * Onderaan de pagina staan alle eigengemaakte classes
 */

/* Links */


/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff;
}

/* Fullpage */
#fullpage {
  display: block;
  width: 100%;
  height: 100vh;
}

.about_welcome {
  background: #333;
  height: 100%;
  width: 100%;
  position: absolute;
}

.horizontal-align {
  color: #fff;
}

.arrow-down {
  position: relative;
  text-align: center;
  font-size: 24px;
  top: 95%;
  color: #fff;
  z-index: 5;
}

@media (max-width: 768px) {
  .arrow-down {
    top: 92.5%;
  }
}

/*
 * Base structure
 */
/* Extra markup and styles for table-esque vertical and horizontal centering */
//.site-wrapper {
//  color: #fff;
//  background-position: center;
//  background: #333;
//  height: 100vh;
//  min-height: 100%;
//  background-size: cover;
//}

.site-wrapper-gallerypages {
  color: #fff;
  text-align: center;
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
  background-color: #fff;
}

//.site-wrapper-inner {
//  box-sizing: border-box;
//  color: rgb(255, 255, 255);
//  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
//  font-size: 14px;
//  line-height: 20px;
//  text-align: center;
//  text-shadow: rgba(0, 0, 0, 0.498039) 0px 1px 3px;
//  vertical-align: middle;
//}

.header-h1 {
}

.cover-container {
  margin-right: auto;
  margin-left: auto;
}

.masthead {
  position: absolute;
  color: #fff;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 10;

  a{
    color: white;
  }
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

/*
 * Header
 */
/* Responsive Nav */
.masthead-brand {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10%;
}

.masthead-nav {
  margin-right: 10%;
  float: right;
}

.masthead-nav > li {
  display: inline-block;
}

.masthead-nav > li + li {
  margin-left: 20px;
}

.masthead-nav > li > a {
  font-size: 16px;
  font-weight: bold;
  color: #fff; /* IE8 proofing */
  color: rgba(255, 255, 255, .75);
  border-bottom: 2px solid transparent;
  padding: 5px 10px;
}

.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #a9a9a9;
  border-bottom-color: rgba(255, 255, 255, .25);
}

.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  color: #fff;
  border-bottom-color: #fff;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: #fff;
}

@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
}

/*
 * Cover
 */

.cover {
  padding: 0 20px;
}

.cover .btn-lg {
  margin-top: 75px;
  padding: 10px 20px;
  font-weight: bold;
}

/*
 * Footer
 */

.mastfoot {
  color: #999; /* IE8 proofing */
  color: rgba(255, 255, 255, .5);
}

/*
 * Affix and center
 */

@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    margin-top: 0;
    width: 100%;
  }

  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }

  /* Handle the widths */
  .masthead,
  .mastfoot,
  .cover-container {
    //width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}

/* Eigengemaakte Classes */

.Masthead__menu-items__item__link {
  color: #3c3b3e;

  &:hover{
    color: #3c3b3e;
    text-decoration: none;
  }
}

