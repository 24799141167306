.ContactForm{
  padding:100px 0;
  width:100%;
  background:rgba(235, 235, 236, 0.75)
}

.ContactForm__Container{
  margin:0 auto;
}

.ContactForm__Text__Light{
  font-size: 28px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif';
  font-weight:300;
  margin-bottom:0;
}

.ContactForm__Text__Regular{
  font-size: 28px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', 'sans-serif';
  font-weight:400;
  margin-bottom:30px;
}

.ContactForm__Container__FormInput{
  width:100%;
  height: 60px;
  background-color: #fff;
  outline: none;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  color: #3c3b3e;
  padding: 20px;
  margin: 0 0 20px 0;
  border:none;
}

.ContactForm__Container__FormInput--Message{
  height:180px;
  resize: none;
}

.ContactForm__Container__FormButton{
  background:#00b4ff;
  width:100%;
  height: 60px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 18px;
  border:none;
  outline:none;
  color:#fff;

  &:hover{

    background-color:#00A2E5;
  }
}

.ContactForm__Container__FormButton__Icon{
  margin-right:30px;
}