/*
* Header
*/
.HeaderFullWidth {
  @media(min-width: 992px) {
    min-height: 460px;
    max-height: 750px;
  }
}

.HeaderFullWidth__button{
  background:#c3122f;
  font-size:18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight:normal;
  border-radius:100px;
  letter-spacing:0;
  padding:8px 49px;
  margin-top: 72px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);

  @media(min-width: 991px) and (max-width: 1440px){
    margin-top: 40px;
  }

  @media(max-width: 767px){
    margin-top: 60px;
  }

  &:hover{
    color: #fff;
    background-color: #ac1029;
  }

  &:focus{
    color: #fff;
    background-color: #ac1029;
  }
}

.HeaderFullWidth__button-text{
  margin:0;
  color: white;
}

.Transition--slide-inTransition--slide-in {
  display: none;
}

.HeaderFullWidth__title--holder {
  display: none;
}

.HeaderFullWidth__FullDiv--Overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/*
* Floating images
*/
.Floating-images{
  @media(max-width:767px)
  {
    padding:0 27px;
  }
}

.Title-images-column__row__image{
  font-size:32px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 38px;
  text-transform: none;
  height: 240px;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  color: white;

  @media(min-width: 768px) and (max-width: 991px){
    height: 160px;
  }
}

.Floating-images__row__content {
  border-radius: 4px;
  font-weight: 600;
  color: white;
  letter-spacing: 5.7px;
  font-size: 35px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.Floating-images__row__content__hover-overlay:hover {
  @media(min-width: 992px) {
    background-image: linear-gradient(237deg, #c41330, #5d4c9f);
  }
}

.Homepage-about-us{
  @media(max-width:768px)
  {
    padding:0 27px;
  }
}


.Homepage-about-us .Half-page-text--even:first-child{
  padding-left: 0;
  padding-right: 0;
}

.Information-column-container__text-holder{
  @media(max-width: 768px)
  {
    padding:0;
  }
}


/*
* Title text button column
*/
.Information-column-container{
  margin:0;
  padding-left: 0;
  padding-right: 0;
  text-align:left;
}

.Information-column-container__header-text {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: #c2122e;
  text-transform: none;
  line-height: 33px;

  @media(max-width: 767px){
    margin-bottom: 12px;
  }
}

.Information-column-container__text-holder {
  font-family: "Roboto", sans-serif;
  font-size:20px;
  line-height: 32px;
  color:#1d1d1d;
  font-weight:300;
  margin-bottom: 30px;
}

.Information-column-container__button--alignment{

  @media(max-width: 767px){
    text-align: center;
  }
}

.Information-column-container__button {
  font-size: 18px;
  font-family:"Roboto", sans-serif;
  color: #fff;
  background-color: #c3122f;
  text-transform: none;
  border-radius:100px;
  margin-top: 0px;
}

.Information-column-container__button:hover {
  background-color: #ac1029;
  color: #fff
}


.img-box{
  height: 300px;
  width: 100%;
  overflow:hidden
}
.wh{
  height:100%!important
}
.ww{
  width:100%!important
}

.home .Half-page-rows{
    display: flex;
    flex: 1;
    min-height: 100%;
    align-items: center;

    @media(max-width: 991px){
      display: block;
    }
}

.Half-page-image__container .home__image{
  background-size: cover;
  background-position: center;
  height: 300px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius:6px;
  padding:0px;

  @media(max-width: 991px){
    margin-top: 30px;
  }
}

/*
* Title images column
*/
.Title-images-column {
  background-color: #ebf0f6;
  font-weight: 600;

}

.Title-images-column__header-text {
  font-size: 30px;
  letter-spacing: 2px;
  color: black;
  font-weight: 600;
  margin-top: 0;
}

.Title-images-column__row__content-and-hover:hover{
  .Title-images-column--hoverstate {
    @media(min-width: 992px) {
      background: linear-gradient(237deg, $red  6%, $gradient-blue  100%);
      opacity: 0.3;
    }
  }
}

.Title-images-column--hoverstate {
  background-image: linear-gradient(237deg, $red  6%, $gradient-blue  100%);
  opacity: 0.6;
  width: 100.2%;
  height: 100.4%;
  border-radius: 4px;
}

/*
* News block
*/
.News-block__container{

  @media(max-width:768px)
  {
    padding-left:27px;
    padding-right:27px;
  }
}

.News-block__header-text {
  font-size: 28px;
  font-weight:500;
  font-family:"Roboto", sans-serif;
  letter-spacing: 0.5px;
  color: #c2122e;
  text-transform:none;
}

.News-block__row__item{

}

.News-block__row__item__text {
  height:215px;
  padding:20px 20px 0 20px;
  position:relative;
  font-family: "Roboto", sans-serif;
  font-weight:300;
  font-size: 26px;
  letter-spacing: 0.5px;
  color: #001221;
  background-color:#fff;
}
