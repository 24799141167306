.Team {
 margin-top: 100px;

 @media(max-width: 767px){
  margin-top: 50px;
 }
}


.Team__title{
  width: 100%;
  margin-bottom: 40px;
}

.Team__title--text{
  color: #c2122e;
  font-size: 28px;
  letter-spacing: 0.5px;
  font-family: 'Roboto', sans-serif;
}

.Team-holder{
  padding-left: 15px;

  @media(max-width: 991px){
    padding-right: 15px;
  }

  @media(max-width: 767px){
    padding-left: 30px;
  }
}

.Team-list {
 // cursor: pointer;
 display: block;
 height: auto;
 float: left;
 border-radius: 6px;
 margin-bottom: 30px;

 @media(max-width: 991px){
  padding-left: 0px;
  margin-bottom: 15px;
 }
}

.Team-list__member {
  position: relative;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.Team-list__member--info {
  height: 100%;
}

.Team-list__member--text-info{
  padding: 20px;
}

.Team-list__member--image {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: cover;
  width: 100%;
  height: 300px;
  transition: all .4s ease-out .1s;
  -moz-transition: all .4s ease-out .1s;
  -webkit-transition: all .4s ease-out .1s;
  
  @media(max-width: 991px){
    height: 240px;
  }

  @media(max-width: 767px){
    height: 500px;
  }

  @media(max-width: 600px){
    height: 375px;
  }

  @media(max-width: 480px){
    height: 250px;
  }
}

.Team-list__member--function {
  font-family:"Roboto", sans-serif;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #c3122f;
  margin-bottom: 10px;
  margin-top: 0px;
  line-height: 12px;
  opacity: 1;
  position: relative;
  left: 2px;
  transition: all .2s ease-out .1s;
  -moz-transition: all .2s ease-out .1s;
  -webkit-transition: all .2s ease-out .1s;

  @media(max-width: 991px){
    line-height: 15px;
  }

}

.Team-list__member--name {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #1D1D1D;
  opacity: 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  transition: all .2s ease-out .1s;
  -moz-transition: all .2s ease-out .1s;
  -webkit-transition: all .2s ease-out .1s;

  @media(max-width: 991px){
    font-size: 22px;
  }
}

// .Team-list__member--info_reveal {
//   height: 100%; 
//   transition: all .3s ease-in 0s;
//   -moz-transition: all .3s ease-in 0s;
//   -webkit-transition: all .23s ease-in 0s;
//   position: absolute;
//   width: 100%;
//   left: 0;
//   text-align: center;
//   top: -100%;
//   background: rgba(255, 90, 95, 0.9);
//   -webkit-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
//   -moz-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
//   box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
// }

// .Team-list__member--info_reveal--personal{
//   padding: 20px 8px 5px 8px;
//   margin-bottom: 50px;
// }

// .Team-list__member--info_reveal--name {
// 	margin-bottom: 20px;
//   font-size: 26px;
// 	color: #fff;
//   opacity: 0.5px;
// }

// .Team-list__member--info_reveal--function{
//   font-size: 14px;
//   color: #fff;
// }

// .Team-list__member--info_reveal p {
//   padding: 0 8px;
//   font-size: 20px;
//   line-height: 1.2em;
//   color: #fff;
//   font-weight: 400;  
// }

// .Team-list__member--email {
//   color: #fff;
// 	word-break: break-word;
//   text-decoration: none;
//   font-size: 20px;
// }

// .Team-list__member--email:hover {
//   color: #fff;
// }

// .Team-list:active .Team-list__member--info_reveal,
// .Team-list:hover .Team-list__member--info_reveal,
// .Team-list:focus .Team-list__member--info_reveal{
//   border-radius: 6px;
//   left: 0;
//   top: 0;
//   transition: all .3s ease .1s;
//   -moz-transition: all .3s ease .1s;
//   -webkit-transition: all .3s ease .1s;
// }

// .Team-list:active .Team-list__member--image,
// .Team-list:hover .Team-list__member--image,
// .Team-list:focus .Team-list__member--image {
//     border-radius: 6px;
//     width: 180%;
//     margin-left: -50%;
//     transition: all .4s ease-in 0s;
//    -moz-transition: all .4s ease-in 0s;
//    -webkit-transition: all .4s ease-in 0s;
// }

// .Team-list:hover .Team-list__member--function,
// .Team-list:focus .Team-list__member--function,
// .Team-list:hover .Team-list__member--name,
// .Team-list:focus .Team-list__member--name {
//     opacity: 0;
//     transition: opacity .2s linear 0s;
//    -moz-transition: opacity .2s linear 0s;
//    -webkit-transition: opacity .2s linear 0s;
// }

