.ScrollIndicator{
  position:fixed;
  right:30px;
  top:740px;
  width:30px;
  height:30px;
  color: rgba(60, 59, 62, 0.2);
  font-size:30px;

  @media(max-width:991px){
    display:none;
  }
}