.Catalogus__row {
  //margin-top: 200px;
  margin-top: 65px;

  margin-bottom: 50px;
}

.Catalogus__row--after-first-row {
  margin-top: 5px;
}

.Catalogus__block {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #f0eff1;
  height: 250px;
  margin-top: 30px;
  margin-right: 30px;
  max-width: 350px;
}

.Catalogus__block__row--title {
  text-transform: uppercase;
  color: #000;
  letter-spacing: 2px;
  font-size: 30px;
  font-family: 'Open Sans';
  font-weight: 500;
}

.Catalogus__block--text {
  font-size: 20px;
  font-family: 'Open Sans';
  color: #000;
}

.Catalogus__block--icon {
  width: 100%;
  margin-top: 17.5%;

  @media (max-width: 991px) {
    margin-top: 20%;
  }
  @media (max-width: 768px) {
    margin-top: 17.5%;
  }
  @media (max-width: 320px) {
    margin-top: 20%;
  }
}


