/**
 * extends components/headers/background_image.scss
 */

.HeaderFullWidthInner{
  display: table;
  height: 100%;
  width: 100%;
}

.HeaderFullWidth__title {
  font-weight    : bold;
  color          : #ffffff;
  letter-spacing : 4.1px;
  height         : 100%;
  text-shadow    : 0px 0px 30px rgba(0, 0, 0, 0.2);
  text-align     : center;
  margin-top     : -60px;

  @media(max-width: 380px){
    margin-top: -30px;
  }
}


/*
* Oude media queries
*/


  // @media (max-width : 1199px) {
  //   height : 506px;
  // }

  // @media(min-width:992px)
  // {
  //   min-height:460px;
  // }

  // @media (max-width : 1500px) {
  //   margin-top:-35px;
  //   text-align:center;
  // }

  // @media (max-width : 991px) {
  //   height         : 450px;
  //   font-size      : 45px;
  //   letter-spacing : 1.6px;
  //   line-height    : 45px;
  //   margin-top:0;
  //   margin:0 auto;
  // }

  // @media (max-width : 768px) {
  //   height         : 422px;
  //   font-size      : 36px;
  //   padding-right:5px;
  //   padding-left:5px;
  //   letter-spacing : 1.6px;
  //   line-height    : 45px;
  //   margin-top:20px;
  //   width:500px;
  // }

  // @media (max-width : 500px) {
  //   width:320px;
  //   height : 316px;
  //   font-size  : 24px;
  // }


  // //iphone (Designer wanted text a little higher on only specific devices)
  // @media(max-width: 361px){
  //   margin-top:-30px;
  // }

.HeaderFullWidth__title--holder {
  width: 900px;
  margin: 0 auto;

    @media (max-width : 991px) {
      width: 700px;
    }

    @media (max-width : 768px) {
      width: 500px;
      margin-top: 50px;
    }

    @media (max-width: 500px){
      width: 400px;
    }
    
    @media(max-width: 380px){
      width: 320px;
    }

    @media (max-width: 320px){
      width: 300px;
    }
}

.HeaderFullWidth__title--h1{
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 86px;
  line-height: 101px;
  letter-spacing: 0px;
  text-transform: none;
  font-weight: bold;
  margin-bottom: 0px;

  @media(max-width: 767px){
    line-height: 50px;
    font-size:40px;
  }

  @media(max-width: 380px){
    font-size: 45px;
  }
}

.HeaderFullWidth__subtitle{
  width: 900px;
  font-size: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  font-style: italic;
  font-weight: 400;
  font-family: 'Merriweather', serif;
  
  @media(max-width: 991px){
    font-size: 30px;
    font-style: normal;
    position: relative;
    top: 20px;
  }
}


/*
* Responsive
*/
//@media(max-width: 991px) {
//  .HeaderFullWidth__title {
//    width: 500px;
//  }
//}
//
//@media(max-width: 768px) {
//  .HeaderFullWidth__title {
//    width: 320px;
//  }
//}

