.Utils__alignment--center {
  text-align: center;
}

.Utils__alignment--left {
  text-align: left;
}

.Utils__alignment--right {
  text-align: right;
}

.Utils__float--left {
  float: left;
}

.Utils__float--right {
  float: right;
}

/**
 * Epic vertical container, only requirement is that the parent of this util has a position relative or absolute : )
 */
.Utils__vertical-container {
  width: 100%;
  height: 100%;
}

.Utils__vertical-container__content {
  width: 100%;
  height: 100%;
  display: table;
  -webkit-backface-visibility: hidden;
}

.Utils__vertical-align--middle {
  display: table-cell;
  vertical-align: middle;
}

.Utils__horizontal-align--center {
  text-align: center;
}

// background positioning
.Utils__background-size--cover {
  background-size: cover;
}

// ----------- display/visibility -----------
.Utils__display--flex {
  display: flex;
}

.Utils__display--none {
  display: none;
}

.Utils__opacity--none {
  opacity: 0;
}

// ----------- flex positioning -----------
// flex align
// add on same class as 'display : flex;'
.Utils__flex__horizontal--center {
  justify-content: center;
}

// add on the object itself - parent is 'display : flex;' - multiple items
// add on the object itself - element has also 'display : flex;' - single item
.Utils__flex__vertical--center {
  align-items: center;
}

.Utils__flex__vertical--start {
  align-self: flex-start;
}

.Utils__flex__vertical--end {
  align-self: flex-end;
}

.Utils__display-flex--1 {
  flex: 1;
}

.Utils__display-flex--2 {
  flex: 2;
}

.Utils__display-flex--3 {
  flex: 3;
}

.Utils__display-flex--4 {
  flex: 4;
}

.Utils__display-flex--6 {
  flex: 6;
}

.Utils__display-flex--8 {
  flex: 8;
}

.Utils__display-flex--10 {
  flex: 10;
}

.Utils__display-flex--12 {
  flex: 12;
}

.Utils__menu__offset {
  padding-top: 70px;
}

.Utils__absolute{
  position:absolute;
}