.Information-column-container {
  text-align: center;
  margin-top: 100px;
  padding-top: 0;
  margin-bottom:100px;
}
.Information-column-container__header-text{
  margin-bottom: 30px;
  margin-top:0;
  text-transform: uppercase;
}

.Information-column-container__text-holder {
  margin-bottom: 50px;
}

.Title__text__column--background-color{
  background-color: #f0eff1;
  width: 100%;
  padding: 25px
}
/*
* Responsive
*/
@media(max-width: 768px) {
  .Information-column-container__text-holder {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .Information-column-container__button {
    padding-left: 47px;
    padding-right: 47px;
  }

  .Information-column-container__text-holder {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
