/*
* Masthead
*/
.Transition--Color {
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.Transition--Background-Color{
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

a:active, a:focus {
}

.Effect--fading {
  -webkit-animation: SetOpacityBG 1s ease-out;
  -moz-animation: SetOpacityBG 1s ease-out;
  -o-animation: SetOpacityBG 1s ease-out;
  animation: SetOpacityBG 1s ease-out;
  background-color: rgba(255, 255, 255, 1);
}

@keyframes SetOpacityBG {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

/*
* Floating images
*/
.Floating-images__row__content__hover-overlay {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
}

.Floating-images__row__content__subtitle {
  -o-transition: opacity .2s ease-out, background 0.2s ease-out;
  -ms-transition: opacity .2s ease-out, background 0.2s ease-out;
  -moz-transition: opacity .2s ease-out, background 0.2s ease-out;
  -webkit-transition: opacity .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: opacity .2s ease-out, background 0.2s ease-out;
}

/*
* Title text button column
*/
.Information-column-container__button {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
}

/*
* Title images column
*/
.Title-images-column--hoverstate {
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: all 0.2s ease-out;
}

/*
* News block
*/
.News-block__row__item__text {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
}

.slideout-panel-darkness, .slideout--searchbg {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
}

.slideout--searchbg--fadeOut {
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
}

.Catalogus__block:hover{
  .icon-catalogus{
  -o-transition: opacity .2s linear;
  -ms-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -webkit-transition: opacity .2s linear;
  /* ...and now override with proper CSS property */
  transition: opacity .2s linear;
  } 
}

/*
* More information hover
*/
.pic-text{
 .pic-text__button:hover{
    .pic-text__more, .icon-arrow-right:before{
    -o-transition: opacity .2s linear;
    -ms-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -webkit-transition: opacity .2s linear;
    /* ...and now override with proper CSS property */
    transition: opacity .2s linear;
    }
  }
} 

.about_us{
  .Photo-swipe-gallery__image--hover{
    -o-transition: opacity .2s linear;
    -ms-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -webkit-transition: opacity .2s linear;
    /* ...and now override with proper CSS property */
    transition: opacity .2s linear;
  }
}

.news_overview{
  .Pagination__pager--link:hover{
  -o-transition: color .2s ease-out, background 0.2s ease-out;
  -ms-transition: color .2s ease-out, background 0.2s ease-out;
  -moz-transition: color .2s ease-out, background 0.2s ease-out;
  -webkit-transition: color .2s ease-out, background 0.2s ease-out;
  /* ...and now override with proper CSS property */
  transition: color .2s ease-out, background 0.2s ease-out;
  }
}