.Footer {
  width: 100%;
  background: linear-gradient(
                  rgba(60, 59, 62, 0.95),
                  rgba(60, 59, 62, 0.95)
  ), url('/front/images/asfalt.jpg');
  font-family: 'roboto', 'sans-serif';
  font-size: 18px;
  line-height: 1.9;
  letter-spacing: 0.1px;
  color: #fff;
  font-weight: 300;
  padding-top: 85px;
  padding-bottom: 85px;

  @media(max-width: 991px) {
    font-size: 15px;
    line-height: 2.0;
  }

  @media(max-width: 767px) {
    padding: 50px 0;
    float: left
  }
}

.LogoStack {
  padding-left: 0;
  width: 20%;

  @media(max-width: 991px) {
    width: 35%;
  }

  @media(max-width: 767px) {
    width: 50%;
    padding-right: 0;
  }
}

.LogoStack__Logo {
  height: 45px;
  width: 152px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 30px;

  @media(max-width: 991px) {
    margin: 30px auto 0 auto;
  }
}

.LogoStack__Logo--drive {
  background-image: url('/front/images/drive.png');

  @media(max-width: 640px) {
    width: 100px;
  }

}

.LogoStack__Logo--repair {
  background-image: url('/front/images/repair.png');
  @media(max-width: 640px) {
    width: 100px;
  }
}

.LogoStack__Logo--coach {
  background-image: url('/front/images/coach.png');
  @media(max-width: 640px) {
    width: 100px;
  }
}

.Footer__Info {
  border-left: 1px solid #fff;
  width: 80%;

  @media(max-width: 991px) {
    width: 65%;
  }

  @media(max-width: 767px) {
    width: 50%;
    padding-left: 30px;
    font-size: 16px;
  }
}

.Footer__Info__Mail {
  color: white;
}

.Footer__Column__Header {
  font-weight: 400;
  margin-bottom: 40px;
}

.Footer__Menu {
  @media(max-width: 767px) {
    margin-top: 40px;
  }
}

.Footer__Column__Content__Menu {
  padding: 0;
  list-style: none;
}

.Footer__Column_Content__Link {
  color: #fff;

  &:hover {
    color: #fff;
  }
}
