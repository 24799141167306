$breakpoints-mobile-max : 991px;
$breakpoints-desktop-min : 992px;
$breakpoints-desktop-normal-max : 1199px;

.Masthead {
  height   : 64px;
  position : fixed;
  width    : 100%;
  z-index  : 3;
  top:0;
  background:#fff;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);


  @media(max-width : 768px) {
    height: 50px;

  }
}

.Masthead--scroll{
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
}

.logo-link{
  text-decoration: none;
}

.HeaderFullWidth__FullDiv--Overlay{
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.30) 1%, rgba(0,0,0,0.00) 30%);
}

.Masthead .container {
  height        : 100%;
  padding-right : 0;
  padding-left  : 0;
}

.Masthead__logo {
  z-index    : 2;
  width      : 60px;
  text-align : left;
  @media(max-width : 768px) {
    width       : 110px;
    margin-left : 14px;
  }
}

.Masthead__logo--icon{
  padding-left: 15px;
  padding-right: 15px;
}

.Masthead__logo img {
  @media(max-width : 768px) {
    width       : 110px;
    margin-left : 14px;
  }
}

.NavBar__Logo__Image{
  width:110px;
}

.background-header-fading-color {
  height : 100%;
  width  : 100%;
}

.Masthead__menu{
  width  : 100%;
}

// Masthead column 2
.Masthead__menu-items {
  float         : left;
  font-size     : 15px;
  margin-bottom : 0;
  padding-left  : 0;
  margin-left   : 30px;
  letter-spacing: 1.1px;
}

.Masthead__menu-items__item {
  display         : inline;
  margin-right    : 40px;
  text-decoration : none;
  font-family     : 'Roboto', sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #3c3b3e;
  font-weight:300;

  @media(max-width: $breakpoints-desktop-normal-max) {
    margin-right : 25px;
  }

  &:hover {
    color: #3c3b3e;
    opacity: 1;   
    cursor : pointer;
  }


  &:active {
    color: #3c3b3e;
    opacity: 1;
  }

  &:focus{
    opacity: 1;
    color: #3c3b3e;
    text-decoration: none;
  }
}

.Masthead__menu-items__item__link{
  &:active{
    color: #3c3b3e;
    opacity: 1;
  }

  &:focus{
    text-decoration: none;
    opacity: 1;
  }
}

.Masthead__menu-items__item__link-active{
  color: #3c3b3e;
  transition: color 0.2s linear;
  opacity: 1;
}

.Masthead__menu-items__item__link-focus{
  color: #3c3b3e;
  opacity: 1.0;
  transition: color 0.2s linear;
}

// Masthead column 3
.Masthead__menu-right {

  height:100%;
  display: -webkit-flex;
  display: flex;
  top:0;

  @media(min-width:991px)
  {
    width : 160px;
  }

  @media(max-width:767px)
  {
    position:absolute;
    right:0;
  }
}

.Masthead__menu-right i {
  color : #fff;
}

.Masthead__menu-right__phone-number{
  color:#fff;
  font-size:15px;
  letter-spacing:1.1px;
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  margin: 0px;
}

.slideout--searchbg{
  @media(max-width:991px)
  {
    background-color:#2b3e6b;
    overflow:hidden;
    position:absolute;
    z-index:2;
    width:100%;
    height:100%;
  }
}

.Dropdown__languages {
  position : relative;
  -webkit-align-self: center;
  align-self: center;

  @media(max-width:991px){
    margin-bottom:-5px;
  }

  @media(max-width:767px){
    margin-bottom:-10px;
  }
}

.Masthead__menu-right__search__button,
.Masthead__menu-right__language-select-button {
  display   :   inline-block;
  cursor    :   pointer;
  position  :   relative;
  right     :   5px;
}

.Masthead__menu-right__search__button {
  margin   : 6px 20px 0 25px;
  position : relative;
  float:left;

  @media(max-width:991px)
  {
    margin   : 0 20px 0 25px;
  }
}

.Masthead__menu-right__search__input {
  position       : absolute;
  right          : 40px;
  top            : -7px;
  padding        : 5px 5px 5px 10px;
  background     : none;
  color          : #fff;
  border         : 0;
  outline        : 0;
  pointer-events : none;
  border-bottom  : 1px solid #fff;
  font-style     : italic;
  width          : 250px;

  @media(max-width: 991px){
    width        : 90vw;
  }

  @media(max-width: 580px){
    width        : 85vw;
  }

  @media(max-width: 480px){
    width        : 80vw;
  }
}

.Masthead__menu-right__search__input--active {
  pointer-events : inherit;
  cursor         : pointer;
  border-bottom  : 1px solid rgba(255, 255, 255, 0.5);

  @media(max-width: 991px){
    border-bottom: none;
  }
}

.toggle-button:hover{
  cursor:pointer;
}

.Masthead__menu-right__search__input--active:hover {
  border-bottom : 1px solid rgba(255, 255, 255, 1);

  @media(max-width: 991px){
    border-bottom: none;
  }
}

.Masthead__language__select__dropdown {
  width       : 205px;
  top         : 40px;
  right       : 0px;
  padding     : 0;
  text-align  : right;
  position    : absolute;
  left        : inherit;
  float       : none;
  margin      : 0;
}

.Masthead__language__select__dropdown > li > a
{
  padding:16px 20px;
}

.Masthead__language--select__current {
  cursor : pointer;
  width: 21px;
}

.Masthead__language__select__image {
  margin-top   : -3px;
  padding-left : 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 24px;
  margin-right:9px;
  height:16px;
  margin-left:11px;
}

.Masthead__language__select__link {
  font-size : 14px;
  padding   : 16px 17px 16px 0px;
}

.Masthead__language__select__dropdown > .Masthead__language__select > .Masthead__language__select__link:first-child:hover{
  border-radius: 4px;
}

.Masthead__language__select__dropdown > .Masthead__language__select > .Masthead__language__select__link:last-child:hover{
  border-radius: 4px;
}

@keyframes Navbar--items--fadeanimation {
  from {opacity:0;}
  to {opacity:1;}
}

.Masthead__Navbar--items--fadeanimation {
  opacity:0;
  animation-name: Navbar--items--fadeanimation;
  animation-duration: 1s;
}

.cover-container{
  //display:none;
}


/*
* Responsive
*/

//to follow up the design I added a 1200 breakpoint to remove padding and solve a big problem
.Masthead__menu-items{
  float:right;
}

.Masthead__menu-items__item__link--demo-link{
  color:#00b4ff;
  font-weight:500;

  &:hover{
    color:#00A2E5;
  }
}
